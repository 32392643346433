<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
    
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="processedLogs"
      :items-per-page="-1"
      dense
      fixed-header
      hide-default-footer
      height="calc(100vh - 250px)"
      class="elevation-1 "
      v-if="stats.userActivity"
      :search="search"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id" dark :class="{error: item.eventError,[item.status]:true}" >
                          <td>
              {{ item.date }} 
            </td>

            <td>
              {{ item.reportTimestamp | time }} ({{
                item.reportTimestamp | ago
              }})
            </td>
            <td @click="search=item.userLoginName">
                <v-icon small :color="item.status === 'connected' ? 'green' :item.status === 'disconnected' ? 'red' : 'grey' ">mdi-account</v-icon>
                <span> {{ item.userLoginName }}</span>
                </td>
            <td>
                <v-icon small>{{item.userEvent | icon}}</v-icon>
                <span> {{ item.userEvent }}</span> <span v-if="item.since"> ({{ item.duration | duration }} later)</span></td>
            <td>
                <span>{{ item.datacenterName }}</span>
                <span  v-if="item.lastDatacenterName">{{ item.lastDatacenterName }}</span>
                </td>
            <td>
                <span>{{ item.poolName }}</span>
                <span v-if="item.lastPoolName">{{ item.lastPoolName }}</span>
            </td>
            <td>
                <v-icon small v-if="item.eventError ">{{item.eventError | icon}}</v-icon>
                <span> {{ item.virtualMachineName }}</span>
                <span v-if="item.lastVirtualMachineName"> (Change: {{ item.lastVirtualMachineName }})</span>
                </td>
            <td>
                <div v-if="item.session">
                <v-icon small>{{item.session.type | icon}}</v-icon>
                <span> {{item.session.loginTime | time}}</span>
                <span> {{item.status}}</span>
                </div>
                <div v-else>
                <v-icon small>mdi-bed</v-icon>
                    <span> Offline</span>
                </div>
                </td>
            <td>{{item.userLogs}} 
                
                </td>
                            <td>
              <div v-if="item.eventErrorDetails">{{ item.eventErrorDetails }}</div>
              <div v-if="item.extraInfo">{{ item.extraInfo }}</div>
            </td>

          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Date", value: "date" },
        { text: "Time", value: "reportTimestamp" },
        { text: "User", value: "userLoginName" },
        { text: "Event", value: "userEvent" },
        { text: "Location", value: "datacenterName" },
        { text: "Pool", value: "poolName" },
        { text: "Desktop", value: "virtualMachineName" },
        { text: "Client/Login/Status", value: "session" },
        { text: "Logs", value: "userLogs" },
        { text: "Notes", value: "eventError" },
        // { text: "eventErrorDetails", value: "eventErrorDetails" },
        // { text: "extraInfo", value: "extraInfo" },
      ],
    };
  },
  computed: {
    processedLogs() {
      let logs = this.stats.userActivity.map((log) => {
        let found
        let userLogs = this.stats.userActivity.filter((item) => {
          return item.userLoginName === log.userLoginName && item.date === log.date;
        });
        log.userLogs = userLogs.length
        userLogs.forEach((item) => {
          if (item.id === log.id) {
            found = true;
          } else if (found) {
            log.duration =
            log.reportTimestamp - item.reportTimestamp;
            log.since = item.reportTimestamp;
            log.lastUserEvent = log.userEvent !== item.userEvent ? item.userEvent : null
            log.lastPoolName = log.poolName !== item.poolName ? item.poolName : null
            log.lastVirtualMachineName = log.virtualMachineName !== item.virtualMachineName ? item.virtualMachineName : null
            found = false;
          }
        });

         let sessions = this.stats.sessions.filter((item) => {
          return item.userName === log.userLoginName;
        });
        log.sessions = sessions.length
        log.sessionDetails = sessions
        log.session = sessions.filter(item=>item.vmName === log.virtualMachineName)[0]

        let desktop = this.stats.desktops.filter(item=>{
            return item.name === log.virtualMachineName && item.userName === log.userLoginName
        })[0]

        log.status = desktop ? desktop.loginStatus : 'offline'

        return log;
      })
      return logs;
    },
  },
  props: ["stats"],
};
</script>

<style>

.v-data-table .error *{
    color:#FFF!important;
    opacity: 1!important;
}
.v-data-table .offline *{
    opacity:0.6;
}

.v-data-table .disconnected{
    background-color:rgba(100, 0, 0, 0.2);
}
</style>