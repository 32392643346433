<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="processedLogs"
      :items-per-page="-1"
      dense
      fixed-header
      hide-default-footer
      height="calc(100vh - 250px)"
      class="elevation-1"
      v-if="stats.userActivity"
      :search="search"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.id"
            dark
            :class="{ error: item.eventError, [item.status]: true }"
          >
            <td>
              <div>{{ item.userName }}</div>
              <div>{{ item.loginStatus }}</div>
              <div>{{ item.loginTime | ago }}</div>
            </td>
            <td>
              <div>{{ item.vmName }}</div>
              <div>{{ item.poolName }}</div>
              <div>{{ item.datacenter }}</div>
            </td>
            <td>
              <div>{{ item.type }} ({{ item.protocol }})</div>
              <div>{{ item.version }}</div>
              <div>{{ item.osName }}</div>
            </td>
            <td>
              {{ item.userActivityLogs.length }} /
              {{ item.desktopActivityLogs.length }}
            </td>
            <td>{{ item.avgDiskIops }} ({{item.avgDiskReadIops}}/{{item.avgDiskWriteIops}})</td>
            <td>{{ item.avgDiskLatencyInMs }}</td>
            <td>{{ item.avgSessionCpuPercentage }}</td>
            <td>{{ item.avgSessionLatencyInMs }}</td>
            <td>{{ item.avgSessionMemoryPercentage }}</td>
            <td>{{ item.estimatedRTT }}</td>
            <td>{{ item.estimatedFPS }}</td>
            <td>{{ Math.round(item.estimatedThroughput/1024) }}</td>
            <td>{{ item.avgHostCpuPercentage }}</td>
            <td>{{ item.avgHostMemoryPercentage }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "User", value: "userName" },
        { text: "vmName", value: "vmName" },
        { text: "client", value: "version" },
        { text: "User/Desktop Logs", value: "userActivityLogs" },
        { text: "Disk IOPS (R/W)", value: "avgDiskIops" },
        { text: "Disk Lat(ms)", value: "avgDiskLatencyInMs" },
        { text: "Cpu %", value: "avgSessionCpuPercentage" },
        { text: "Latency(ms)", value: "avgSessionLatencyInMs" },
        { text: "RAM %", value: "avgSessionMemoryPercentage" },
        { text: "RTT(ms)", value: "estimatedRTT" },
        { text: "FPS", value: "estimatedFPS" },
        { text: "Throughput", value: "estimatedThroughput" },
        { text: "Host CPU %", value: "avgHostCpuPercentage" },
        { text: "HOST RAM %", value: "avgHostMemoryPercentage" },
      ],
    };
  },
  computed: {
    processedLogs() {
      let activeDesktops = this.stats.desktops.filter((item) => item.active);
      return activeDesktops.map((item) => {
        return {
          userName: item.userName,
          loginStatus: item.loginStatus,
          loginTime: item.loginTime,
          protocol: item.protocol,
          datacenter: item.datacenter.friendlyName,
          poolName: item.poolName,
          vmName: item.name,
          desktopActivityLogs: item.desktopActivityLogs,
          userActivityLogs: item.userActivityLogs,
          osName: item.osName,
          type: item.sessionClient.type,
          version: item.sessionClient.version.split("-")[0],
          avgDiskIops: item.sessionPerformance.avgDiskIops,
          avgDiskLatencyInMs: item.sessionPerformance.avgDiskLatencyInMs,
          avgDiskReadIops: item.sessionPerformance.avgDiskReadIops,
          avgDiskWriteIops: item.sessionPerformance.avgDiskWriteIops,
          avgHostCpuPercentage: item.sessionPerformance.avgHostCpuPercentage,
          avgHostMemoryPercentage:
            item.sessionPerformance.avgHostMemoryPercentage,
          avgSessionCpuPercentage:
            item.sessionPerformance.avgSessionCpuPercentage,
          avgSessionLatencyInMs: item.sessionPerformance.avgSessionLatencyInMs,
          avgSessionMemoryPercentage:
            item.sessionPerformance.avgSessionMemoryPercentage,
          estimatedRTT: item.sessionProtocol.estimatedRTT,
          estimatedFPS: item.sessionProtocol.estimatedFPS,
          estimatedThroughput: item.sessionProtocol.estimatedThroughput,
        };
      });
    },
  },
  props: ["stats"],
};
</script>

<style>
.v-data-table .error * {
  color: #fff !important;
  opacity: 1 !important;
}
.v-data-table .offline * {
  opacity: 0.6;
}

.v-data-table .disconnected {
  background-color: rgba(100, 0, 0, 0.2);
}
</style>