<template>
  <div>
    <v-alert v-if="error" type="error">
      <h3>{{ error.code }}</h3>
      <div>{{ error.message }}</div>
      <hr />
      <em
        ><i
          ><small>{{ error }}</small></i
        ></em
      >
    </v-alert>

    <v-tabs v-model="tab">
      <v-tab :disabled="loading">Events</v-tab>
      <v-tab :disabled="loading">
        <span
          v-if="existingEvent && existingEvent.event && existingEvent.event.id"
        >
          Update Event</span
        >
        <span v-else> Create Event</span>
      </v-tab>
      <v-tab :disabled="loading" v-if="valid">Preview Event</v-tab>
      <v-tab :disabled="loading" v-if="valid">Notification</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>View</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>

            <v-calendar
              ref="calendar"
              :events="calendarEvents"
              @click:event="selectEvent"
              v-model="focus"
              :type="type"
            />

            <!-- {{tableEvents}} -->
            <v-simple-table dense v-if="tableEvents.length">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Status</th>
          <th class="text-left">Activity Description</th>
          <th class="text-left">Date Opened</th>
          <th class="text-left">Date Planned</th>
          <th class="text-left">Date Completed</th>
          <th class="text-left">Owner of Next Action</th>
          <th class="text-left">Impact</th>
        </tr>
      </thead>
      <tbody @click="test">
        <tr
          v-for="item in tableEvents"
          :key="item.payload.event.id"
        >
        <td  :style="{background: item.Status == 'Open' ? '' : ''}">{{ item.Status }}</td>
        <td v-html="item['Activity Description']"></td>
        <td>{{ item['Date Opened'] }}</td>
        <td>{{ item['Date Planned'] }}</td>
        <td>{{ item['Date Completed'] }}</td>
        <td>{{ item['Owner of Next Action'] }}</td>
        <td>{{item['Impact']}}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>

            <!-- <div
              @click="selectEvent(maintenanceEvent.payload)"
              v-for="maintenanceEvent in formattedEvents"
              :key="maintenanceEvent.payload.event.id"
            >
            <div>cancelled: {{ maintenanceEvent.cancelled }}</div>
            <div>subject: {{ maintenanceEvent.subject }}</div>
            <div>when: {{ maintenanceEvent.when }}</div>
            <div v-html="maintenanceEvent.body "></div>
            <div>
            </div>
            </div> -->
          </v-card-text></v-card
        ></v-tab-item
      >
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-text-field v-model="subject" label="Subject"></v-text-field>

            <AutoComplete
              label="Platform(s)"
              multiple
              :items="locationOptions"
              v-model="locations"
            ></AutoComplete>
            <v-select
              v-model="severity"
              :items="severities"
              label="Severity"
              return-object
            ></v-select>
            <div class="d-flex">
              <FieldDate
                v-model="date"
                :options="{ autoOpen: false }"
                type="customDate"
              />
              <FieldTime v-model="time" />
              <FieldTimezone v-model="timezone" class="ml-1" />
              <v-text-field
                type="number"
                v-model="duration"
                label="Duration (mins)"
                class="ml-1"
                step="5"
              ></v-text-field>
            </div>

            <WYSIWYGVue
              v-model="what"
              label="What is happening?"
              height="200px"
            />
            <WYSIWYGVue
              v-model="impact"
              label="Impact to clients and users?"
              height="150px"
            />
            <WYSIWYGVue
              v-model="notes"
              label="Additional Notes (Optional)"
              height="150px"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="reset"
              :disabled="loading"
              :loading="loading"
  
            >
              Reset
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              @click="cancelCalendarEvent"
              color="error"
              :disabled="loading"
              :loading="loading"
              v-if="
                existingEvent && existingEvent.event && existingEvent.event.id
              "
            >
              CANCEL Event
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn @click="tab = 2" color="primary" :disabled="!valid">
              Preview</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <div>
          <v-card>
            <v-card-title>SUBJECT: {{ subjectFormatted }}</v-card-title>
            <hr />

            <v-card-text>
              <div v-html="bodyFormatted"></div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="cancelCalendarEvent"
                color="error"
                :disabled="loading"
                :loading="loading"
                v-if="
                  existingEvent && existingEvent.event && existingEvent.event.id
                "
              >
                CANCEL Event
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn @click="tab = 3" color="primary"> Preview Users </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div>
          <v-card>
            <v-card-title>Notification</v-card-title>
            <hr />

            <v-card-text>
              <div>
                Clients:
                <v-chip
                  small
                  label
                  class="mr-1 mb-1"
                  color="primary"
                  v-for="client in includedClients"
                  :key="client.id"
                  >{{ client.name }}</v-chip
                >
              </div>
              <div>
                Users:
                <v-chip
                  small
                  label
                  class="mr-1 mb-1"
                  color="secondary"
                  v-for="attendee in attendees"
                  :key="attendee.emailAddress.address"
                  >{{ attendee.emailAddress.name }}</v-chip
                >
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="cancelCalendarEvent"
                color="error"
                :disabled="loading"
                :loading="loading"
                v-if="
                  existingEvent && existingEvent.event && existingEvent.event.id
                "
              >
                CANCEL Event
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn
                @click="createCalendarEvent"
                color="success"
                :disabled="loading"
                :loading="loading"
              >
                <span
                  v-if="
                    existingEvent &&
                    existingEvent.event &&
                    existingEvent.event.id
                  "
                >
                  Update Event</span
                >
                <span v-else> Create Event</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../firebase";
const functions = firebase.functions();
let createCalendarEvent = functions.httpsCallable("createCalendarEvent");
let updateCalendarEvent = functions.httpsCallable("updateCalendarEvent");
let cancelCalendarEvent = functions.httpsCallable("cancelCalendarEvent");

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
// import isBefore from "dayjs/plugin/isBefore";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
// dayjs.extend(isBefore);

import WYSIWYGVue from "../../components/fields/WYSIWYG.vue";

import FieldDate from "../../components/fields/FieldDate.vue";
import FieldTime from "../../components/fields/FieldTime.vue";
import FieldTimezone from "../../components/fields/FieldTimezone.vue";
import AutoComplete from "../../components/fields/AutoComplete.vue";
import platforms from "../../utils/platforms";
import { mapState } from "vuex";

const db = firebase.firestore();

export default {
  components: {
    FieldDate,
    FieldTime,
    WYSIWYGVue,
    AutoComplete,
    FieldTimezone,
  },
  data() {
    return {
      focus: "",
      type: "month",
      saving: false,
      tab: 0,
      subject: "",
      notes: "",
      error: null,
      loading: false,
      maintenanceEvents: [],
      date: dayjs().format("YYYY-MM-DD"),
      time: {},
      timezone: "EST5EDT",
      duration: 60,
      what: "",
      impact: "",
      locations: [],
      categories: ["Maintenance"],
      severity: {},
      severities: [
        {
          text: "P3 - No disruption expected",
          value: "P3",
        },
        {
          text: "P2 - Team members may experience a brief disruption or may need to alter how they use the platform to avoid disruption",
          value: "P2",
        },
        {
          text: "P1 - Team members will not be able to use the platform at times during the maintenance window",
          value: "P1",
        },
      ],
      locationOptions: platforms,
      existingEvent: {},
      transactionId: null,
    };
  },
  watch: {
    time: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log({ val });
        if (val.endTime === '00:00:00' || val.endTime === '00:00'){
          this.time.endTime = '23:59'
        }
        if (!this.date) return;
        if (val.startTime && !val.endTime && this.duration) {
          console.log(this.start);
        } else if (val.startTime && val.endTime) {
          this.duration = dayjs
            .duration(this.end.diff(this.start))
            .as("minutes");
          console.log(val);
        }
      },
    },
    duration(val) {
      if (this.start && val) {
        console.log("CHANGE DUR");
        let newEndTime = this.start.add(val, "minutes").format("HH:mm:ss");
        if (newEndTime !== this.time.endTime) {
          this.time.endTime = newEndTime;
        }
      }
    },
  },
  computed: {
    tableEvents(){
      return this.calendarEvents.map(evt=>{
        console.log(evt)
        let div=document.createElement('div')
        div.innerHTML = evt.payload.impact
        return {
          'Status': dayjs(evt.end).isBefore(dayjs) ? 'Open' : 'Closed',
          'Activity Description': evt.payload.what,
          'Date Opened': dayjs(evt.created).format('YYYY-MM-DD'),
          'Date Planned': dayjs(evt.start).format('YYYY-MM-DD'),
          'Date Completed': dayjs(evt.end).isBefore(dayjs) ? '' : dayjs(evt.start).format('YYYY-MM-DD'),
          'Owner of Next Action': evt.payload?.event?.organizer?.emailAddress?.name,
          'Impact': div.innerText,
          'payload': evt.payload
        }
      })
    },
    ...mapState(["clients", "user"]),
    calendarEvents() {
      return this.maintenanceEvents.map((item) => {
        return {
          name: item.event.subject.split(": ")[1].split(" - ")[0],
          start: dayjs(item.start.toDate()).toDate(),
          end: dayjs(item.end.toDate()).toDate(),
          timed: true,
          color: item.cancelled ? "warning" : "primary",
          payload: item,
        };
      });
    },
    formattedEvents() {
      return this.maintenanceEvents
        .map((item) => {
          return {
            cancelled: item.cancelled,
            when: `${dayjs(item.start.toDate()).format(
              "ddd, MMM D, YYYY"
            )} ${dayjs(item.start.toDate()).format("h:mma")} - ${dayjs(
              item.end.toDate()
            ).format("h:mma")}`,
            created: dayjs(item.created.toDate()).format(
              "ddd, MMM D, YYYY - h:mma"
            ),
            updated: dayjs(item.updated.toDate()).format(
              "ddd, MMM D, YYYY - h:mma"
            ),
            subject: item.event.subject,
            body: item.event.bodyPreview + "...",
            organizer: item.event.organizer.emailAddress.name,
            payload: item,
            timestamp: item.start.toDate(),
          };
        })
        .sort((a, b) => {
          return a.timestamp > b.timestamp
            ? 1
            : a.timestamp < b.timestamp
            ? -1
            : 0;
        });
    },
    includedClients() {
      return this.clients.filter((client) => {
        let match = (client.platforms || []).filter(
          (item) => this.locations.indexOf(item) > -1
        );
        return match.length > 0;
      });
    },
    attendees() {
      let users = {};
      this.includedClients.forEach((item) => {
        item.maintenanceNotice.forEach((user) => {
          if (user.address.toLowerCase() == this.user.email.toLowerCase()) {
            return;
          }
          users[user.address] = {
            type: "optional",
            emailAddress: { name: user.displayName, address: user.address },
          };
        });
      });

      //   [
      //       {
      //         emailAddress: {
      //           name: "Jeff Kramp",
      //           address: "Jeff Kramp@VIPdeskConnect.com",
      //         },
      //       },
      //       {
      //         emailAddress: {
      //           name: "Orville Herndon",
      //           address: "Orville.Herndon@VIPdeskConnect.com",
      //         },
      //       },

      // },
      return Object.keys(users).map((user) => users[user]);
    },
    valid() {
      console.log(
        this.subject.length,
        this.date.length,
        this.severity.value,
        this.locations.length,
        this.what.length,
        this.impact.length,
        this.time?.startTime?.length,
        this.time?.endTime?.length
      );
      return (
        this.subject.length > 3 &&
        this.date.length == 10 &&
        this.severity.value !== undefined &&
        this.locations.length > 0 &&
        this.what.length > 10 &&
        this.impact.length > 10 &&
        this.time?.startTime?.length > 0 &&
        this.time?.endTime?.length > 0
      );
    },
    start() {
      return this.date && this.time.startTime
        ? dayjs
            .tz(`${this.date} ${this.time.startTime}`, this.timezone)
            .utc()
            .tz("EST5EDT")
        : null;
    },
    end() {
      let end =
        this.date && this.time.endTime
          ? dayjs
              .tz(`${this.date} ${this.time.endTime}`, this.timezone)
              .utc()
              .tz("EST5EDT")
          : null;
      if (this.start && end && dayjs(end).isBefore(this.start)) {
        end = dayjs(end).add(1, "day").utc().tz("EST5EDT");
      }
      return end;
    },
    whenFormatted() {
      let format = "ddd, MMM D, YYYY @ h:mma";
      let start = this.start ? this.start.format(format) : null;
      let end = this.end ? this.end.format(format) : null;
      if (!start || !end) return "";
      console.log({ start, end });
      // this.duration = dayjs.duration(this.end.diff(this.start)).as("minutes");
      return `Start: ${start}<br/>
      End: ${end}<br/>
      Duration: ${this.duration} minutes
      `;
    },
    bodyFormatted() {
      let body = `
      <h1>VIPdesk Maintenance Notice</h1>
      <table style="width:90%;margin: 10px; border: 1px solid #AAAAAA;border-collapse: collapse;">
        <tr style="vertical-align: top;border-bottom: 1pt solid #AAAAAA;"><th style="width:200px;text-align:left; padding: 5px;"><strong>When is it happening:</strong></th><td style=" padding: 5px;">${
          this.whenFormatted
        }</td></tr>
        <tr style="vertical-align: top;border-bottom: 1pt solid #AAAAAA;"><th style="width:200px;text-align:left; padding: 5px;"><strong>Platforms Affected:</strong></th><td style=" padding: 5px;">${this.locations.join(
          ", "
        )}</td></tr>
        <tr style="vertical-align: top;border-bottom: 1pt solid #AAAAAA;"><th style="width:200px;text-align:left; padding: 5px;"><strong>Severity:</strong></th><td style=" padding: 5px;">${
          this.severity.text
        }</td></tr>
        <tr style="vertical-align: top;border-bottom: 1pt solid #AAAAAA;"><th style="width:200px;text-align:left; padding: 5px;"><strong>What work is being done:</strong></th><td style=" padding: 5px;">${
          this.what
        }</td></tr>

        <tr style="vertical-align: top;border-bottom: 1pt solid #AAAAAA;"><th style="width:200px;text-align:left; padding: 5px;"><strong>What is the expected impact:</strong></th><td style=" padding: 5px;">${
          this.impact
        }</td></tr>`;
      if (this.notes.length) {
        body += `<tr style="vertical-align: top;"><th style="width:200px;text-align:left; padding: 5px;"><strong>Additional Notes:</strong></th><td style=" padding: 5px;">${this.notes}</td></tr>`;
      }

      body += `</table>
        <p>You are receiving this notice because you have requested to be added to VIPdesk Maintenance Notices.</p>
        <p>If you no longer wish to receive these, please reply with 'Unsubscribe' in the body.</p>
        <p>Thank you,<br/>The VIPdesk Technology Team</p>

      `;
      return body;
    },
    subjectFormatted() {
      let formattedDate = dayjs(this.date).format("ddd, MMM D, YYYY");
      let subject = `VIPdesk Maintenance: ${this.subject} - ${formattedDate} - [${this.severity.value}]`;
      return subject;
    },
  },
  methods: {
    test(a,b,c){
      console.log(a,b,c)
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    selectEvent(evt) {
      let payload = evt?.event?.payload || evt;
      console.log(payload);
      if (payload.cancelled) return;
      this.existingEvent = payload;
      this.date = dayjs(payload.start.toDate()).format("YYYY-MM-DD");
      this.time = {
        startTime: dayjs(payload.start.toDate()).format("HH:mm:ss"),
        endTime: dayjs(payload.end.toDate()).format("HH:mm:ss"),
      };
      this.subject = payload.event.subject.split(": ")[1].split(" - ")[0];
      this.locations = payload.event.locations.map((item) => item.displayName);
      console.log("DONE");
      let div = document.createElement("div");
      div.innerHTML = payload.event.body.content;
      let tds = div.querySelectorAll("td");
      console.log({ tds });
      this.severity = {
        value: (tds[2].innerText || "").split(" ")[0],
        text: tds[2].innerText,
      };
      this.what = tds[3].innerHTML || "";
      this.impact = tds[4].innerHTML || "";
      this.notes = tds[5] ? tds[5].innerHTML : "";
      tds.forEach((item) => {
        console.log(item.innerHTML);
      });
      this.tab = 1;
    },
    getMaintenanceEvents() {
      return new Promise((resolve, reject) => {
        try {
          let query = db.collection("maintenance");
          // if (access?.clients?.length) {
          //     query = query.where("access.clients", 'array-contains-any', access.clients)
          // }

          query.onSnapshot((querySnapshot) => {
            let result = [];
            querySnapshot.forEach((doc) => {
              console.log("NEW schedules", doc);
              result.push(doc.data());
            });
            this.maintenanceEvents = result;
            resolve(this.maintenanceEvents);
          });
          return;
        } catch (err) {
          return reject(err);
        }
      });
    },
    reset() {
      this.loading = false;
      this.saving = false;
      this.error = null;
      this.subject = "";
      this.date = dayjs().format("YYYY-MM-DD");
      this.time = {};
      this.what = "";
      this.impact = "";
      this.severity = "";
      this.priority = 3;
      this.locations = [];
      this.categories = ["Maintenance"];
      this.transactionId = null;
      this.tab = 0;
      this.existingEvent = {}
    },
    async cancelCalendarEvent() {
      this.loading = true;
      await cancelCalendarEvent({
        ...this.existingEvent.event,
        comment:
          "This event is cancelled. If it has been rescheduled, you will receive another notification.",
      });
      let maintenanceDoc = await db
        .collection("maintenance")
        .doc(this.existingEvent?.event?.id);
      await maintenanceDoc.set(
        { cancelled: true, updated: new Date() },
        { merge: true }
      );
      this.reset();
    },
    async createCalendarEvent() {
      let now = dayjs().utc().tz("EST5EDT").toDate();
      this.loading = true;
      this.saving = true;
      let locations = this.locations.map((displayName, index) => ({
        displayName,
        uniqueId: displayName,
        uniqueIdType: "private",
        locationType: index == 0 ? "default" : undefined,
      }));
      let location = locations[0];
      let importance = this.severity.value === 'P1' ? 'high' : this.severity.value == 'P2' ? 'normal' : 'low'
      let dateFormat = "YYYY-MM-DDTHH:mm:ss";
      let start = this.start.format(dateFormat);
      let end = this.end.format(dateFormat);

      let payload = {
        subject: this.subjectFormatted,
        hideAttendees: true,
        categories: this.categories,
        start: {
          dateTime: start,
          timeZone: "Eastern Standard Time",
        },
        end: {
          dateTime: end,
          timeZone: "Eastern Standard Time",
        },
        body: {
          contentType: "HTML",
          content: this.bodyFormatted,
        },
        organizer: {
          emailAddress: {
            name: this.user.displayName,
            address: this.user.email,
            // address: 'admin@vipdeskconnect.com',
          },
        },
        showAs: "free",
        responseRequested: false,
        reminderMinutesBeforeStart: 720,
        attendees: this.attendees,
        location,
        locations,
        importance,
        transactionId: uuidv4(),
      };
      if (this.existingEvent?.event?.id) {
        payload.id = this.existingEvent.event.id;
        payload.transactionId = this.existingEvent.event.transactionId;
        payload.organizer = this.existingEvent.event.organizer;
        payload.attendees = this.existingEvent.event.attendees;
      }
      console.log(JSON.stringify(payload));
      let resp = await (this.existingEvent?.event?.id
        ? updateCalendarEvent(payload)
        : createCalendarEvent(payload));
      this.loading = false;
      if (resp.data.error) {
        this.error = resp.data.error;
        return;
      }
      console.log(resp);
      let maintenanceDoc = await db
        .collection("maintenance")
        .doc(this.existingEvent?.event?.id || resp.data.id);

      let doc = {
        updated: now,
        canceled: false,
        start: this.start.toDate(),
        end: this.end.toDate(),
        error: resp.data.error || null,
        event: resp.data,
        what: this.what,
        impact: this.impact,
        notes: this.notes,
      };
      if (!this.existingEvent?.event?.id) {
        doc.created = now;
      }

      await maintenanceDoc.set(doc, { merge: true });
      this.reset();
      this.saving = false;
    },
  },
  mounted() {
    this.getMaintenanceEvents();
  },
};
</script>
