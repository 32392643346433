<template><v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        
      ></v-text-field>
    </v-card-title>
  <v-data-table
    :search="search"
    :headers="headers"
    :items="desktops"
    :items-per-page="-1"
          fixed-header
      hide-default-footer
      height="calc(100vh - 250px)"
    dense
    class="elevation-1"
    sort-by="loginTime"
    sort-desc
    v-if="desktops"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <tr
          v-for="item in items"
          :key="item.id"
          @click="selectedDesktop = item"
        >
          <td>
            <v-progress-circular
              v-if="item.isProcessing"
              indeterminate
              color="primary"
            ></v-progress-circular>
            {{item.isReady}}
            {{item.isProcessing}}
          </td>
          <td>
            <div>
              <v-icon
                small
                v-if="item.vmpowerState == 'POWERED_ON'"
                color="green"
                >{{ item.vmpowerState | icon }}</v-icon
              >
              <template v-else>
                <v-icon small>{{ item.vmpowerState | icon }}</v-icon>
                {{ item.isReady }}
              </template>
              {{ item.name }}
            </div>
            <div @click="search = item.poolName">
              <v-icon small>mdi-account-group</v-icon> {{ item.poolName }}
            </div>
            <div>
              <v-icon small>mdi-ip-network</v-icon> {{ item.ipAddress }}
            </div>

            <!-- <div>{{ item.allocateState }}</div>
              <div>{{ item.sessionAllocationState }}</div> -->
          </td>
          <td>
            <v-tooltip bottom v-if="item.loginTime">
              <template v-slot:activator="{ on, attrs }">
                <div
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.loginTime"
                >
                  <v-icon small>mdi-account</v-icon>
                  {{ item.loginTime | ago }}
                </div>
              </template>
              <span>{{ item.loginTime | time }}</span>
            </v-tooltip>

            <div title="Desktop Recycled">
              <v-icon small>mdi-restart</v-icon> {{ item.powerOnDate | ago }}
            </div>
            <div title="Desktop Created">
              <v-icon small>mdi-content-copy</v-icon>
              {{ item.vmlifeStateDate | ago }}
            </div>
          </td>
          <!-- <td>{{ item.powerOnDate | ago }}</td>
            <td>{{ item.loginTime | ago }}</td> -->
          <td>
            <div v-if="item.userName">
              <div><v-icon small>mdi-account</v-icon> {{ item.userName }}</div>
              <div>
                <v-icon small>mdi-ip-network</v-icon>
                <span v-if="item.sessionClient">
                {{ item.sessionClient.ipAddress }}
                </span>
              </div>
              <div>
                <v-icon small>mdi-laptop</v-icon>
                <span v-if="item.sessionClient">
                {{ item.sessionClient.machineName }}
                </span>
              </div>
            </div>
          </td>
          <td>
            <div v-if="item.loginStatus">
              <div>
                <v-icon small>{{ item.loginStatus | icon }} </v-icon>
                {{ item.loginStatus }}
              </div>
              <div>
                <v-icon small v-if="item.sessionClient && item.sessionClient.type">
                </v-icon>
                <span> {{ item.osName }} </span>
              </div>
              <div>
                <v-icon small>mdi-code-tags</v-icon>
                                                    <span v-if="item.sessionClient">
                {{ item.sessionClient.version }}
                                                    </span>
              </div>
            </div>
          </td>
          <!-- <td>
              <div>
                <v-icon small>
                  {{ item.osType | icon }}
                </v-icon>
                <span>{{ item.osName }} </span>
              </div>
            </td> -->
          <!-- <td>
              <v-icon>
                {{ item.osType | icon }}
              </v-icon>
            </td> -->
          <!-- <td>{{ item.clientVersion }}</td> -->
          <!-- <td>{{ item.sessionLatencyInMs }}</td>
            <td>{{ item.avgSessionLatencyInMs }}</td>
            <td>{{ item.hostCpuPercentage }}</td>
            <td>{{ item.avgHostCpuPercentage }}</td>
            <td>{{ item.estimatedRTT }}</td>
            <td>{{ item.estimatedFPS }}</td>
            <td>{{ item.estimatedThroughput }}</td> -->
          <td>{{ item.agentErrorCode }}</td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
          </v-card>
</template>

<script>
export default {
  data() {
    return {
        search:'',
      headers: [
        { text: "", value: "vmpowerState" },
        { text: "Desktop", value: "name" },
        { text: "Timestamps", value: "loginTime" },
        // { text: "Recycled", value: "vmlifeStateDate" },
        // { text: "Log On", value: "loginTime" },
        { text: "User", value: "userName" },
        { text: "loginStatus", value: "loginStatus" },
        // { text: "User OS", value: "userAgentOrHostOsName" },
        // { text: "osType", value: "osType" },
        // { text: "clientVersion", value: "clientVersion" },
        // { text: "datacenter", value: "datacenter",hidden:true },
        // { text: "sessionLatencyInMs", value: "sessionLatencyInMs" },
        // { text: "avgSessionLatencyInMs", value: "avgSessionLatencyInMs" },
        // { text: "hostCpuPercentage", value: "hostCpuPercentage" },
        // { text: "avgHostCpuPercentage", value: "avgHostCpuPercentage" },
        // { text: "estimatedRTT", value: "estimatedRTT" },
        // { text: "estimatedFPS", value: "estimatedFPS" },
        // { text: "estimatedThroughput", value: "estimatedThroughput" },
        { text: "agentErrorCode", value: "agentErrorCode" },
      ],
    }
  },
  props:[
      'desktops'
  ]
};
</script>

<style>
*[aria-haspopup="true"]{
    cursor: pointer;
}
</style>