<template>
  <div class="editor flex-column d-flex" :style="{ height }">
    <!-- <div v-if="editor">
      {{height}}
      <button
        @click="
          editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run()
        "
      >
        insertTable
      </button>
      <button
        @click="editor.chain().focus().addColumnBefore().run()"
        :disabled="!editor.can().addColumnBefore()"
      >
        addColumnBefore
      </button>
      <button
        @click="editor.chain().focus().addColumnAfter().run()"
        :disabled="!editor.can().addColumnAfter()"
      >
        addColumnAfter
      </button>
      <button
        @click="editor.chain().focus().deleteColumn().run()"
        :disabled="!editor.can().deleteColumn()"
      >
        deleteColumn
      </button>
      <button
        @click="editor.chain().focus().addRowBefore().run()"
        :disabled="!editor.can().addRowBefore()"
      >
        addRowBefore
      </button>
      <button
        @click="editor.chain().focus().addRowAfter().run()"
        :disabled="!editor.can().addRowAfter()"
      >
        addRowAfter
      </button>
      <button
        @click="editor.chain().focus().deleteRow().run()"
        :disabled="!editor.can().deleteRow()"
      >
        deleteRow
      </button>
      <button
        @click="editor.chain().focus().deleteTable().run()"
        :disabled="!editor.can().deleteTable()"
      >
        deleteTable
      </button>
      <button
        @click="editor.chain().focus().mergeCells().run()"
        :disabled="!editor.can().mergeCells()"
      >
        mergeCells
      </button>
      <button
        @click="editor.chain().focus().splitCell().run()"
        :disabled="!editor.can().splitCell()"
      >
        splitCell
      </button>
      <button
        @click="editor.chain().focus().toggleHeaderColumn().run()"
        :disabled="!editor.can().toggleHeaderColumn()"
      >
        toggleHeaderColumn
      </button>
      <button
        @click="editor.chain().focus().toggleHeaderRow().run()"
        :disabled="!editor.can().toggleHeaderRow()"
      >
        toggleHeaderRow
      </button>
      <button
        @click="editor.chain().focus().toggleHeaderCell().run()"
        :disabled="!editor.can().toggleHeaderCell()"
      >
        toggleHeaderCell
      </button>
      <button
        @click="editor.chain().focus().mergeOrSplit().run()"
        :disabled="!editor.can().mergeOrSplit()"
      >
        mergeOrSplit
      </button>
      <button
        @click="
          editor
            .chain()
            .focus()
            .setCellAttribute('backgroundColor', '#FAF594')
            .run()
        "
        :disabled="!editor.can().setCellAttribute('backgroundColor', '#FAF594')"
      >
        setCellAttribute
      </button>
      <button
        @click="editor.chain().focus().fixTables().run()"
        :disabled="!editor.can().fixTables()"
      >
        fixTables
      </button>
      <button
        @click="editor.chain().focus().goToNextCell().run()"
        :disabled="!editor.can().goToNextCell()"
      >
        goToNextCell
      </button>
      <button
        @click="editor.chain().focus().goToPreviousCell().run()"
        :disabled="!editor.can().goToPreviousCell()"
      >
        goToPreviousCell
      </button>

      <bubble-menu
        class="bubble-menu"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
      >
        <button
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          Bold
        </button>
        <button
          @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          Italic
        </button>
        <button
          @click="editor.chain().focus().toggleStrike().run()"
          :class="{ 'is-active': editor.isActive('strike') }"
        >
          Strike
        </button>
        <button
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          Bullet List
        </button>
      </bubble-menu>

      <floating-menu
        class="floating-menu"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
      >
        <button
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        >
          H1
        </button>
        <button
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        >
          H2
        </button>
        <button
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          Bullet List
        </button>
      </floating-menu>
    </div> -->
    <div>{{ label }}</div>
    <bubble-menu
      :editor="editor"
      :tippy-options="{ duration: 100 }"
      v-if="editor"
    >
    <v-card outline color="primary" class="pa-1">
      <v-btn-toggle dense multiple>

        <v-btn
          x-small
          @click="edit('clean')"
          icon
        >
          <v-icon>mdi-eraser</v-icon>
        </v-btn>
        <v-btn
          x-small
          @click="edit('cleanAll')"
          icon
        >
          <v-icon>mdi-nuke</v-icon>
        </v-btn>
        </v-btn-toggle>
        &nbsp;

      <v-btn-toggle dense multiple>
        <v-btn
          x-small
          @click="edit('bold')"
          icon
          :class="{ 'v-item--active': editor.isActive('bold') }"
        >
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>
        <v-btn
          x-small
          @click="edit('italic')"
          icon
          :class="{ 'v-item--active': editor.isActive('italic') }"
        >
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>
        <v-btn
          x-small
          @click="edit('underline')"
          icon
          :class="{ 'v-item--active': editor.isActive('underline') }"
        >
          <v-icon>mdi-format-underline</v-icon>
        </v-btn>
        <v-btn
          x-small
          @click="edit('strike')"
          icon
          :class="{ 'v-item--active': editor.isActive('strike') }"
        >
          <v-icon>mdi-format-strikethrough</v-icon>
        </v-btn>
      </v-btn-toggle>
      &nbsp;

      <v-btn-toggle dense>
        <v-btn
          x-small
          @click="edit('h1')"
          icon
          :class="{ 'v-item--active': editor.isActive('heading') }"
        >
          <v-icon>mdi-format-header-1</v-icon>
        </v-btn>
        <v-btn x-small @click="edit('h2')" icon>
          <v-icon>mdi-format-header-2</v-icon>
        </v-btn>
        <v-btn x-small @click="edit('h3')" icon>
          <v-icon>mdi-format-header-3</v-icon>
        </v-btn>
      </v-btn-toggle>

      &nbsp;
      <v-btn-toggle dense >
        <v-btn x-small @click="edit('ul')" icon>
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
        <v-btn x-small @click="edit('ol')" icon>
          <v-icon>mdi-format-list-numbered</v-icon>
        </v-btn>
      </v-btn-toggle>
      &nbsp;

      <v-btn-toggle dense >
        <v-btn x-small @click="edit('left')" icon
        :class="{ 'is-active': editor.isActive({ textAlign: 'left' })}">
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>

        <v-btn x-small @click="edit('center')" icon
        :class="{ 'is-active': editor.isActive({ textAlign: 'center' })}">
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>
        
        <v-btn x-small @click="edit('right')" icon
        :class="{ 'is-active': editor.isActive({ textAlign: 'right' })}">
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
      </v-btn-toggle>

    </v-card>
    </bubble-menu>
    <editor-content
      :editor="editor"
      class="flex-column d-flex flex-grow-1 overflow-y-auto"
    />
    <!-- <div class="clipboard" ref="clipboard" :style="{height:height}" style="height:100px;overflow: auto;"></div> -->
  </div>
</template>

<script>
import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Document from "@tiptap/extension-document";
import Image from "@tiptap/extension-image";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import Heading from "@tiptap/extension-heading";
import Underline from "@tiptap/extension-underline";
// import History from "@tiptap/extension-history";

import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

export default {
  components: {
    EditorContent,
    BubbleMenu,
    // BubbleMenu,
    // FloatingMenu,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "100px",
    },
    label: {
      type: String,
      default: "",
    },
  },

  emits: ["update:value"],

  data() {
    return {
      editor: null,
      json: null,
      isSame: false,
      html: "",
    };
  },
  watch: {
    value(value) {
      // HTML
      console.log("value", value);
      let html = this.editor.getHTML();
      let isSame = html === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }
      this.html = html;
      this.editor.commands.setContent(value, false);
    },
  },
  methods: {
    edit(cmd) {
      switch (cmd) {
        case "clean":
          this.editor.commands.unsetAllMarks();

          break;
          case "cleanAll":
          this.editor.commands.selectAll();
          this.editor.commands.toggleNode('paragraph', 'heading')
          this.editor.commands.unsetAllMarks();
          // // this.editor.commands.toggleHeading({ level: 6 })
          // // this.editor.commands.toggleHeading({ level: 4 })
          // this.editor.commands.setTextAlign('left');
          // this.editor.commands.unsetAllMarks();
          // this.editor.commands.unsetAllMarks();
          // this.editor.commands.unsetHeading()
          this.editor.commands.clearNodes()

          // // this.editor.chain().selectAll().toggleNode('heading', 'paragraph').run();
          // this.editor.chain().selectAll().unsetAllMarks().run();
          // // this.editor.chain().selectAll().unsetHeading().run();
          // this.editor.chain().selectAll().run()


          // this.editor.chain().focus().setTextAlign('left').run();

          // this.editor.chain().focus().unsetAllMarks().run();
          // this.editor.chain().focus().selectTextblockStart().run();
          break;
          case "bold":
          this.editor.chain().focus().toggleBold().run();
          break;
        case "strike":
          this.editor.chain().focus().toggleStrike().run();
          break;
          case "italic":
          this.editor.chain().focus().toggleItalic().run();
          break;
        case "underline":
          this.editor.chain().focus().toggleUnderline().run();
          break;
        case "ul":
          this.editor.chain().focus().toggleBulletList().run();
          break;
        case "ol":
          this.editor.chain().focus().toggleOrderedList().run();
          break;
        case "h1":
          this.editor.chain().focus().toggleHeading({ level: 1 }).run();
          break;
        case "h2":
          this.editor.chain().focus().toggleHeading({ level: 2 }).run();
          break;
        case "h3":
          this.editor.chain().focus().toggleHeading({ level: 3 }).run();
          break;
        case "left":
        case "right":
          console.log('EDIT ',cmd)
          this.editor.chain().focus().setTextAlign(cmd).run();
          break;
          case "center":
          this.editor.chain().focus().setTextAlign('center').run();
          break;
          case "undo":
          this.editor.commands.undo()
          break;
          case "redo":
          this.editor.commands.redo()
          break;

      }
    },
    copyToClipboard(html) {
      console.log(html);
      this.$refs.clipboard.innerHTML = html;
      const type = "text/html";
      const blob = new Blob([html], { type });
      const data = [new window.ClipboardItem({ [type]: blob })];
      navigator.clipboard.write(data);
    },
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        // Default TableCell
        // TableCell,
        // Custom TableCell with backgroundColor attribute
        CustomTableCell,
        Highlight,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
         Document,
        Image,
        Paragraph,
        Text,
        ListItem,
        OrderedList,
        BulletList,
        Heading,
        Underline,
        // History,
      ],
      content: this.value,
      onUpdate: () => {
        // HTML

        if (this.isSame) {
          return;
        }

        this.json = this.editor.getJSON();
        let html = this.editor
          .getHTML()
          .replace(/(VIPdesk Connect)/gim, "VIPdesk")
          .replace(/(VIPdeskConnect)/gim, "VIPdesk")
          .replace(/(VP of Technology)/gim, "CTO");

        if (html === this.html) return;
        this.html = html;
        this.$emit("input", html);
        this.$emit("onchange", html);
        // this.copyToClipboard(html);
        // JSON
        // this.$emit('update:value', this.editor.getJSON())
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>
<style lang="scss">
.editor .ProseMirror {
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 10px;
  flex: 1;
}

.clipboard {
  display: none;
}
.ProseMirror button {
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  background: #fff;
}
.ProseMirror {
  // height: 300px;
  overflow: auto;
}

/* Table-specific styling */
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>