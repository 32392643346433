<template>
  <div>
    <Stats :stats="stats" />
    <!-- {{ nextRefresh }}
    {{ connected }}
    {{ Array.from(allocateStates) }}
    {{ queries }}
    {{ timestamp | time }} -->

    <!-- <pre>
          {{testDesktop}}
      </pre> -->
    <!-- <pre>
          {{stats.activeDesktops}}
      </pre>
    <pre>
          {{stats.desktops}}
      </pre>
        -->
    <!-- <pre>
          {{ desktops[0] }}
      </pre
    > -->
    <v-tabs v-model="tab">
      <v-tab>Desktops</v-tab>
      <v-tab>User Activity</v-tab>
      <v-tab>System Activity</v-tab>
      <v-tab>Session Performance</v-tab>
    </v-tabs>
    <v-progress-linear
      v-if="nextRefresh > 0"
      height="5"
      striped
      :value="+((nextRefresh / 60) * 100).toFixed(0)"
    ></v-progress-linear>
    <v-progress-linear
      v-else
      indeterminate
      height="5"
      striped
      color="red"
      value="100"
    ></v-progress-linear>
    <v-tabs-items v-model="tab">
      <v-tab-item><DesktopTable :desktops="stats.desktops" /></v-tab-item>
      <v-tab-item><UserActivityTable :stats="stats"/></v-tab-item>
      <v-tab-item><SystemActivityTable :systemActivity="stats.systemActivity"/></v-tab-item>
      <v-tab-item><SessionPerformanceTable :stats="stats"/></v-tab-item>
    </v-tabs-items>
    <v-text-field v-model.trim="search" label="Search" />

    <DesktopLogs :desktop="selectedDesktop" @close="selectedDesktop = null" />
  </div>
</template>

<script>
// import dayjs from "dayjs";
import ws from "../../utils/ws";
import processVdiStats from "../../utils/processVdiStats";
import dayjs from "dayjs";
import DesktopLogs from "./DesktopLogs.vue";
import DesktopTable from "./DesktopTable.vue";
import UserActivityTable from "./UserActivityTable.vue";
import SystemActivityTable from "./SystemActivityTable.vue";
import SessionPerformanceTable from "./SessionPerformanceTable.vue";
import Stats from "./Stats";
export default {
  data() {
    return {
      selectedDesktop: null,
      nextRefresh: 0,
      nextStatsTimer: null,
      connected: ws.connected,
      timestamp: null,
      queries: 0,
      search: "",
      stats: {},
      tab: 0,
      allocateStates: new Set(),
      headers: [
        { text: "", value: "vmpowerState" },
        { text: "Desktop", value: "name" },
        { text: "Timestamps", value: "loginTime" },
        // { text: "Recycled", value: "vmlifeStateDate" },
        // { text: "Log On", value: "loginTime" },
        { text: "User", value: "userName" },
        { text: "loginStatus", value: "loginStatus" },
        // { text: "User OS", value: "userAgentOrHostOsName" },
        // { text: "osType", value: "osType" },
        // { text: "clientVersion", value: "clientVersion" },
        // { text: "sessionLatencyInMs", value: "sessionLatencyInMs" },
        // { text: "avgSessionLatencyInMs", value: "avgSessionLatencyInMs" },
        // { text: "hostCpuPercentage", value: "hostCpuPercentage" },
        // { text: "avgHostCpuPercentage", value: "avgHostCpuPercentage" },
        // { text: "estimatedRTT", value: "estimatedRTT" },
        // { text: "estimatedFPS", value: "estimatedFPS" },
        // { text: "estimatedThroughput", value: "estimatedThroughput" },
        { text: "agentErrorCode", value: "agentErrorCode" },
      ],
    };
  },
  components: {
    DesktopLogs,
    DesktopTable,
    UserActivityTable,
    SystemActivityTable,
    SessionPerformanceTable,
    Stats
  },
  methods: {
    // parseOS(desktop) {
    //   if (!desktop.userAgentOrHostOsName) {
    //     return desktop.userAgentOrHostOsName;
    //   } else if (desktop.userAgentOrHostOsName.match("Windows")) {
    //     return desktop.userAgentOrHostOsName.split(",")[0];
    //   }
    //   if (
    //     desktop.userAgentOrHostOsName.match("macOS ") ||
    //     desktop.userAgentOrHostOsName.match("OS X ")
    //   ) {
    //     return desktop.userAgentOrHostOsName.split(" (")[0];
    //   } else {
    //     const browser = Bowser.getParser(desktop.userAgentOrHostOsName);
    //     console.log({ browser }, desktop.userAgentOrHostOsName);
    //     if (browser?.parsedResult?.name) {
    //       return `${browser.parsedResult.os.name} ${browser.parsedResult.os.versionName}`;
    //     } else {
    //       console.log("HUH", desktop);
    //     }
    //     return desktop.userAgentOrHostOsName;
    //   }
    // },
    // isProcessing(desktop) {
    //   return (
    //     desktop.agentErrorCode === "DT_AGENT_ERR_STARTUP_IN_PROGRESS" ||
    //     desktop.allocateState === "recycled"
    //   );
    // },
    // isReady(desktop) {
    //   let ready =
    //     desktop.vmwareToolsState === "OK" &&
    //     desktop.daaSAgentState === "ACTIVE" &&
    //     desktop.state === "Success" &&
    //     desktop.vmpowerState === "POWERED_ON" &&
    //     desktop.sessionAllocationState === "AVAILABLE" &&
    //     (desktop.allocateState === "available" ||
    //       desktop.allocateState === "allocated") &&
    //     desktop.ipAddress != null &&
    //     desktop.viewAgentPaired === true &&
    //     desktop.agentPairingAcknowledged === true &&
    //     desktop.agentErrorCode === "";
    //   if (!ready) {
    //     console.log("NOT READY", desktop);
    //   }
    //   return ready;
    // },
    nextStats(duration = 17) {
      clearInterval(this.nextStatsTimer);

      let next =
        this.stats.eta ||
        dayjs()
          .add(1, "minute")
          .startOf("minute")
          .add(duration, "seconds")
          .valueOf();
        this.nextRefresh = +((next - Date.now()) / 1000).toFixed(1);
      this.nextStatsTimer = setInterval(() => {
        this.nextRefresh = +((next - Date.now()) / 1000).toFixed(1);
      }, 200);
    },
  },
  computed: {
    testDesktop() {
      return this.desktops.filter((item) => item.name === "AARP-Test-E-123")[0];
    },

    desktops() {
      let desktops = this.stats.desktops;
      if (!desktops) return [];
      // let mergedData = {}
      // Object.keys(this.stats.dcs).forEach(dc=>{
      //     Object.keys(this.stats.dcs[dc].data).forEach(key=>{
      //         mergedData[key] = mergedData[key] || []
      //         mergedData[key] = [...mergedData[key],...this.stats.dcs[dc].data[key]]
      //     })
      // })
      // console.log(Object.keys(mergedData))
      // console.log(mergedData.pools)
      // let activeDesktops = this.stats.activeDesktops || [];

      // let desktops = (this.stats.desktops || []).map((desktop) => {
      //   let match = activeDesktops.filter(
      //     (item) => item.vmId === desktop.vmId
      //   )[0];
      //   desktop.active = false;
      //   this.allocateStates.add(desktop.allocateState);
      //   this.allocateStates.add(desktop.sessionAllocationState);

      //   if (match) {
      //     desktop.active = true;
      //     desktop = { ...desktop, ...match };
      //     let sessionClient = this.stats.sessionClient.filter(
      //       (item) => item.vmName === desktop.name
      //     )[0];
      //     if (sessionClient) {
      //       desktop = {
      //         ...desktop,
      //         ...{
      //           osType: sessionClient.type,
      //           clientVersion: sessionClient.version,
      //           localIP: sessionClient.ipAddress,
      //           machineName: sessionClient.machineName,
      //         },
      //       };
      //     }
      //     let sessionPerformance = this.stats.sessionPerformance.filter(
      //       (item) => item.vmName === desktop.name
      //     )[0];
      //     if (sessionPerformance) {
      //       desktop = {
      //         ...desktop,
      //         ...{
      //           sessionLatencyInMs: sessionPerformance.sessionLatencyInMs,
      //           avgSessionLatencyInMs: sessionPerformance.avgSessionLatencyInMs,
      //           hostCpuPercentage: sessionPerformance.hostCpuPercentage,
      //           avgHostCpuPercentage: sessionPerformance.avgHostCpuPercentage,
      //         },
      //       };
      //     }
      //     let sessionProtocol = this.stats.sessionProtocol.filter(
      //       (item) => item.vmName === desktop.name
      //     )[0];
      //     if (sessionProtocol) {
      //       desktop = {
      //         ...desktop,
      //         ...{
      //           estimatedRTT: sessionProtocol.estimatedRTT,
      //           estimatedFPS: sessionProtocol.estimatedFPS,
      //           estimatedThroughput: sessionProtocol.estimatedThroughput,
      //         },
      //       };
      //     }
      //   }
      //   //   let dates = [
      //   //     "powerOnDate",
      //   //     "vmlifeStateDate",
      //   //     "skipTimestamp",
      //   //     "queryTimestamp",
      //   //     "loginTime",
      //   //   ];
      //   //   dates.forEach((key) => {
      //   //     if (!desktop[key]) return;
      //   //     desktop[key] = dayjs(desktop[key]).format("YYYY-MM-DD h:mma");
      //   //   });
      //   desktop.searchTerms =
      //     `${desktop.name} ${desktop.poolName} ${desktop.osType} ${desktop.userAgentOrHostOsName} ${desktop.vmpowerState} ${desktop.userName} ${desktop.machineName} ${desktop.loginStatus} ${desktop.ipAddress} ${desktop.allocateState} ${desktop.clientVersion}`.toLowerCase();

      //   desktop.isReady = this.isReady(desktop);
      //   desktop.isProcessing = this.isProcessing(desktop);
      //   desktop.osName = this.parseOS(desktop);
      //   desktop.lastTimestamp = desktop.loginTime || desktop.vmlifeStateDate;

      //   desktop.desktopActivityLogs = this.stats.userActivity.filter(log=>log.virtualMachineName === desktop.name)
      //   desktop.systemActivityLogs = this.stats.systemActivity.filter(log=>log.vmName === desktop.name)
      //   desktop.userActivityLogs = this.stats.userActivity.filter(log=>log.userLoginName === desktop.userName)
      //   desktop.session = this.stats.sessions.filter(log=>log.userName === desktop.userName)
      //   desktop.sessionProtocol = this.stats.sessionProtocol.filter(log=>log.userName === desktop.userName)
      //   desktop.sessionPerformance = this.stats.sessionPerformance.filter(log=>log.userName === desktop.userName)
      //   desktop.sessionClient = this.stats.sessionClient.filter(log=>log.userName === desktop.userName)

      //   desktop.datacenter = this.stats.sessionClient.filter(log=>log.userName === desktop.userName)

      //   return desktop;
      // });
      if (this.search) {
        return desktops.filter((desktop) => {
          let searchTerms =
            `${desktop.name} ${desktop.poolName} ${desktop.osType} ${desktop.userAgentOrHostOsName} ${desktop.vmpowerState} ${desktop.userName} ${desktop.machineName} ${desktop.loginStatus} ${desktop.ipAddress} ${desktop.allocateState} ${desktop.clientVersion}`.toLowerCase();

          searchTerms.match(this.search.toLowerCase());
        });
      }
      // desktops.sort((a, b) => {
      //   return a.lastTimestamp > b.lastTimestamp
      //     ? -1
      //     : a.lastTimestamp < b.lastTimestamp
      //     ? 1
      //     : 0;
      // });
      // .filter((desktop) => desktop.active);
      // activeDesktops.forEach(desktop=>{
      //     let match = desktops.filter(item=>item.id === desktop.id)[0]
      //     if (match){
      //         match.active = match
      //     }
      // })
      return desktops;
    },
  },
  beforeDestroy() {
    console.log("LEAVE");
    ws.leave("vdi-stats");
  },
  mounted() {
    this.nextStats();
    ws.join("vdi-stats");
    ws.on("stats", (stats) => {
      console.log("GOT STATS", stats);
      this.stats = processVdiStats(stats);
      console.log(this.stats);
      // let mergedData = {};
      // this.queries = 0;
      // this.timestamp = stats.start;
      // Object.keys(stats.dcs).forEach((dc) => {
      //   this.queries += stats.dcs[dc].queries;
      //   Object.keys(stats.dcs[dc].data).forEach((key) => {
      //     mergedData[key] = mergedData[key] || [];
      //     mergedData[key] = [...mergedData[key], ...stats.dcs[dc].data[key]];
      //   });
      // });
      // this.stats = mergedData;
      this.nextStats();
    });
  },
};
</script>

<style>
tr:nth-child(even) {
  background-color: #ddd;
}
</style>