import Bowser from "bowser"


const consolidate = stats => {
    let mergedData = {
        queries: 0,
        date: stats.date,
        start: stats.start,
        end: stats.end,
        duration: stats.duration,
        eta: stats.end + 60 * 1000,
    }

    Object.keys(stats.dcs).forEach((dc) => {
        mergedData.queries += stats.dcs[dc].queries
        Object.keys(stats.dcs[dc].data).forEach((key) => {
            if (key === 'datacenters') {
                stats.dcs[dc].data[key].forEach(item => {
                    item.host = dc
                })
            }
            mergedData[key] = mergedData[key] || []
            mergedData[key] = [...mergedData[key], ...stats.dcs[dc].data[key]]

        })
    })
    return mergedData
}

const parseOS = (desktop) => {
    if (!desktop.userAgentOrHostOsName) return desktop.userAgentOrHostOsName
     if (desktop.userAgentOrHostOsName.match(/^(Windows )/)) {
        return desktop.userAgentOrHostOsName.split(",")[0]
    }
    if (
        desktop.userAgentOrHostOsName.match(/^(macOS )/) ||
        desktop.userAgentOrHostOsName.match(/^(OS X )/)
    ) {
        return desktop.userAgentOrHostOsName.split(" (")[0].replace(' Version', '')
    } else {
        const browser = Bowser.getParser(desktop.userAgentOrHostOsName)
    console.log({ browser }, desktop.userAgentOrHostOsName)
    if (browser?.parsedResult?.os?.name) {
        return `${browser.parsedResult.os.name} ${browser.parsedResult.os.versionName}`
    } else
        console.log("HUH", desktop, desktop.userAgentOrHostOsName)
        return desktop.userAgentOrHostOsName
    }
}

const getDesktops = (stats, datacenters) => {
    if (!stats.desktops || !stats.desktops.length) return

    let desktops = stats.desktops
        .map((desktop) => {
            let activeDesktop = stats.activeDesktops.filter(
                (item) => item.vmId === desktop.vmId
            )[0]
            desktop.active = false
            if (activeDesktop) {
                desktop.active = true
                desktop = { ...desktop, ...activeDesktop }
            }

            //9999999999999
            // desktop.lastTimestamp = (desktop.loginTime || 0) + desktop.vmlifeStateDate
            desktop.lastTimestamp = desktop.loginTime || desktop.vmlifeStateDate
            desktop.desktopActivityLogs = stats.userActivity.filter(log => log.virtualMachineName === desktop.name)
            desktop.systemActivityLogs = stats.systemActivity.filter(log => log.vmName === desktop.name)
            desktop.userActivityLogs = stats.userActivity.filter(log => log.userLoginName === desktop.userName)
            desktop.lastUserActivityLog = desktop.userActivityLogs ? desktop.userActivityLogs[0] : null
            desktop.session = stats.sessions.filter(log => log.userName === desktop.userName)[0]
            desktop.sessionProtocol = stats.sessionProtocol.filter(log => log.userName === desktop.userName)[0]
            desktop.sessionPerformance = stats.sessionPerformance.filter(log => log.userName === desktop.userName)[0]
            desktop.sessionClient = stats.sessionClient.filter(log => log.userName === desktop.userName)[0]

            desktop.datacenter = datacenters.filter(dc => dc.desktopManagerName === desktop.desktopManagerName)[0]

            desktop.isReady =
                desktop.vmwareToolsState === "OK" &&
                desktop.daaSAgentState === "ACTIVE" &&
                desktop.state === "Success" &&
                desktop.vmpowerState === "POWERED_ON" &&
                desktop.sessionAllocationState === "AVAILABLE" &&
                (desktop.allocateState === "available" ||
                    desktop.allocateState === "allocated") &&
                desktop.ipAddress != null &&
                desktop.viewAgentPaired === true &&
                desktop.agentPairingAcknowledged === true &&
                desktop.agentErrorCode === ""

            desktop.isProcessing = (desktop.agentErrorCode === "DT_AGENT_ERR_STARTUP_IN_PROGRESS" ||
                desktop.allocateState === "recycled")
            desktop.osName = parseOS(desktop)

            return desktop
        })

    desktops.sort((a, b) => {
        return a.lastTimestamp > b.lastTimestamp
            ? -1
            : a.lastTimestamp < b.lastTimestamp
                ? 1
                : 0
    })
    return desktops
}

const getDatacenters = stats => {
    return stats.desktopManagers.map(desktopManager => {
        let datacenter = stats.datacenters.filter(dc => dc.id === desktopManager.dataCenterId)[0]
        return {
            id: datacenter.id,
            name: datacenter.name,
            dnsServer: datacenter.dnsServer,
            friendlyName: datacenter.friendlyName,
            gateway: datacenter.gateway,
            ipAddressBlock: datacenter.ipAddressBlock,
            desktopManagerName: desktopManager.name,//MATCH IN DESKTOP
            host: datacenter.host,//MATCH IN DESKTOP
        }
    })
}


const processVdiStats = (stats) => {

    let consolidated = consolidate(stats)

    let datacenters = getDatacenters(consolidated)

    let desktops = getDesktops(consolidated, datacenters)

    let sessions = consolidated.sessions.map(session=>{
        let client = consolidated.sessionClient.filter(client => client.id===session.id)[0] || {}
        let desktop = desktops.filter(desktop=>desktop.name === session.vmName)[0]
        session.datacenter = desktop.datacenter.friendlyName
        return {...session,...client}
    })

    return {
        queries: consolidated.queries,
        date: consolidated.date,
        start: consolidated.start,
        end: consolidated.end,
        duration: consolidated.duration,
        eta: consolidated.eta,
        datacenters,
        desktops,
        capacity: consolidated.capacity,
        pools: consolidated.pools,
        systemActivity: consolidated.systemActivity,
        userActivity: consolidated.userActivity,
        sessions,
    }
}

export default processVdiStats