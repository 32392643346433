import dayjs from 'dayjs'

const alerts = (stats) => {
    if (!stats || !stats.desktops)return {}
    let lastHour = dayjs().subtract(1, 'hour').valueOf()

    let activeDesktops = stats.desktops
    .filter(item => item.active)

    let desktopAllocation = stats.desktops.reduce((obj, desktop) => {
        let pool = desktop.poolName || 'Unknown'
        obj.totals[desktop.allocateState] = obj.totals[desktop.allocateState] || 0
        obj.totals[desktop.allocateState]++

        obj.pools[pool] = obj.pools[pool] || {}
        obj.pools[pool][desktop.allocateState] = obj.pools[pool][desktop.allocateState] || 0
        obj.pools[pool][desktop.allocateState]++
        return obj
    }, { count: stats.desktops.length, totals: {}, pools: {} })

    let userEventsToday = parseUserEvents(stats.userActivity)
    let userEventsLastHour = parseUserEvents(stats.userActivity, lastHour)

    let loginStatus = activeDesktops
        .reduce((obj, desktop) => {
            let pool = desktop.poolName || 'Unknown'

            obj.totals[desktop.loginStatus] = obj.totals[desktop.loginStatus] || 0
            obj.totals[desktop.loginStatus]++

            obj.pools[pool] = obj.pools[pool] || {}
            obj.pools[pool][desktop.loginStatus] = obj.pools[pool][desktop.loginStatus] || 0
            obj.pools[pool][desktop.loginStatus]++
            return obj
        }, { count: activeDesktops.length, totals: {}, pools: {} })

        let clientAllocation = activeDesktops
        .reduce((obj, desktop) => {
            let pool = desktop.poolName || 'Unknown'

            let type = desktop?.sessionClient?.type  || 'Unknown'
            if(!desktop?.sessionClient?.type){
                console.log('YIKES', desktop)
            }
            obj.totals[type] = obj.totals[type] || 0
            obj.totals[type]++

            obj.pools[pool] = obj.pools[pool] || {}
            obj.pools[pool][type] = obj.pools[pool][type] || 0
            obj.pools[pool][type]++
            return obj
        }, { count: activeDesktops.length, totals: {}, pools: {} })


    let osAllocation = activeDesktops
        .reduce((obj, desktop) => {
            let pool = desktop.poolName || 'Unknown'

            let key = desktop.osName
            obj.totals[key] = obj.totals[key] || 0
            obj.totals[key]++

            obj.pools[pool] = obj.pools[pool] || {}
            obj.pools[pool][key] = obj.pools[pool][key] || 0
            obj.pools[pool][key]++
            return obj
        }, { count: activeDesktops.length, totals: {}, pools: {} })


        
        let protocolAllocation = activeDesktops
        .reduce((obj, desktop) => {
            let pool = desktop.poolName || 'Unknown'

            let key = desktop.session.protocol
            obj.totals[key] = obj.totals[key] || 0
            obj.totals[key]++

            obj.pools[pool] = obj.pools[pool] || {}
            obj.pools[pool][key] = obj.pools[pool][key] || 0
            obj.pools[pool][key]++
            return obj
        }, { count: activeDesktops.length, totals: {}, pools: {} })

    let versionAllocation = activeDesktops
        .reduce((obj, desktop) => {
            let pool = desktop.poolName || 'Unknown'

            let version = (desktop?.sessionClient?.version || 'Unknown').split('-')[0]
            obj.totals[version] = obj.totals[version] || 0
            obj.totals[version]++

            obj.pools[pool] = obj.pools[pool] || {}
            obj.pools[pool][version] = obj.pools[pool][version] || 0
            obj.pools[pool][version]++
            return obj

        }, { count: activeDesktops.length, totals: {}, pools: {} })

    let performance = activeDesktops
        .reduce((obj, desktop, index) => {
            let pool = desktop.poolName || 'Unknown'

            obj.totals.rtt = obj.totals.rtt || 0
            obj.totals.rtt += desktop.sessionProtocol.estimatedRTT

            obj.totals.fps = obj.totals.fps || []
            obj.totals.fps.push(desktop.sessionProtocol.estimatedFPS)

            obj.totals.diskIops = obj.totals.diskIops || 0
            obj.totals.diskIops += desktop.sessionPerformance.diskIops

            obj.totals.diskLatencyInMs = obj.totals.diskLatencyInMs || 0
            obj.totals.diskLatencyInMs += desktop.sessionPerformance.diskLatencyInMs

            obj.totals.sessionLatencyInMs = obj.totals.sessionLatencyInMs || 0
            obj.totals.sessionLatencyInMs += desktop.sessionPerformance.sessionLatencyInMs

            obj.totals.sessionCpuPercentage = obj.totals.sessionCpuPercentage || 0
            obj.totals.sessionCpuPercentage += desktop.sessionPerformance.sessionCpuPercentage

            obj.totals.sessionMemoryPercentage = obj.totals.sessionMemoryPercentage || 0
            obj.totals.sessionMemoryPercentage += desktop.sessionPerformance.sessionMemoryPercentage

            if (activeDesktops.length === index+1){
                obj.totals.rtt = obj.totals.rtt / activeDesktops.length
                obj.totals.fps = obj.totals.fps[Math.round(obj.totals.fps.length/2)]
                obj.totals.diskIops = obj.totals.diskIops / activeDesktops.length
                obj.totals.diskLatencyInMs = obj.totals.diskLatencyInMs / activeDesktops.length
                obj.totals.sessionLatencyInMs = obj.totals.sessionLatencyInMs / activeDesktops.length
                obj.totals.sessionCpuPercentage = obj.totals.sessionCpuPercentage / activeDesktops.length
                obj.totals.sessionMemoryPercentage = obj.totals.sessionMemoryPercentage / activeDesktops.length
            }

            obj.pools[pool] = obj.pools[pool] || {}

            obj.pools[pool].rtt = obj.pools[pool].rtt || 0
            obj.pools[pool].rtt += desktop.sessionProtocol.estimatedRTT

            obj.pools[pool].fps = obj.pools[pool].fps || []
            obj.pools[pool].fps.push(desktop.sessionProtocol.estimatedFPS)

            obj.pools[pool].diskIops = obj.pools[pool].diskIops || 0
            obj.pools[pool].diskIops += desktop.sessionPerformance.diskIops

            obj.pools[pool].diskLatencyInMs = obj.pools[pool].diskLatencyInMs || 0
            obj.pools[pool].diskLatencyInMs += desktop.sessionPerformance.diskLatencyInMs

            obj.pools[pool].sessionLatencyInMs = obj.pools[pool].sessionLatencyInMs || 0
            obj.pools[pool].sessionLatencyInMs += desktop.sessionPerformance.sessionLatencyInMs

            obj.pools[pool].sessionCpuPercentage = obj.pools[pool].sessionCpuPercentage || 0
            obj.pools[pool].sessionCpuPercentage += desktop.sessionPerformance.sessionCpuPercentage

            obj.pools[pool].sessionMemoryPercentage = obj.pools[pool].sessionMemoryPercentage || 0
            obj.pools[pool].sessionMemoryPercentage += desktop.sessionPerformance.sessionMemoryPercentage
            
            if (activeDesktops.length === index+1){
                obj.pools[pool].rtt = obj.pools[pool].rtt / activeDesktops.length
                obj.pools[pool].fps = obj.pools[pool].fps[Math.round(activeDesktops.length/2)]
                obj.pools[pool].diskIops = obj.pools[pool].diskIops / activeDesktops.length
                obj.pools[pool].diskLatencyInMs = obj.pools[pool].diskLatencyInMs / activeDesktops.length
                obj.pools[pool].sessionLatencyInMs = obj.pools[pool].sessionLatencyInMs / activeDesktops.length
                obj.pools[pool].sessionCpuPercentage = obj.pools[pool].sessionCpuPercentage / activeDesktops.length
                obj.pools[pool].sessionMemoryPercentage = obj.pools[pool].sessionMemoryPercentage / activeDesktops.length
            }

            return obj

        }, { count: activeDesktops.length, totals: {}, pools: {} })


    return {
        desktopAllocation,
        clientAllocation,
        userEventsToday,
        userEventsLastHour,
        loginStatus,
        osAllocation,
        versionAllocation,
        performance,
        protocolAllocation
    }
}

const parseUserEvents = (items, lastHour) => {
    let date = dayjs().format('YYYY-MM-DD')
    let events = lastHour ? items.filter(item => item.reportTimestamp >= lastHour) : items.filter(item => item.date === date)
    return events.reduce((obj, item) => {
        obj.totals[item.userEvent] = obj.totals[item.userEvent] || 0
        obj.totals[item.userEvent]++

        let pool = item.poolName || 'Unknown'
        obj.pools[pool] = obj.pools[pool] || {}
        obj.pools[pool][item.userEvent] = obj.pools[pool][item.userEvent] || 0
        obj.pools[pool][item.userEvent]++
        return obj
    }, { totals: {}, pools: {} })
}

export default alerts