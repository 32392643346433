import { io } from "socket.io-client"
const jwt = require('jsonwebtoken')

let token = jwt.sign({ rooms: {
    'vdi-stats': true
} }, '12345')

let rooms = []

let connectedOnce

console.log('RECONNECT')
const socket = io("https://ws.vipdeskconnect.com", {
  auth: {
    token
  }
});

socket.join = (room)=>{
  socket.emit('join', room)
  rooms = rooms.filter(item=>item !== room)
  rooms.push(room)
  console.log('JOINED', room, rooms)
}

socket.leave = (room)=>{
  if (socket.connected){
    socket.emit('leave', room)
    rooms = rooms.filter(item=>item !== room)
  }
  console.log('LEFT', room, rooms)
}

socket.on("connect", () => {
  console.log('connect',socket.id,connectedOnce); // x8WIv7-mJelg7on_ALbx
  if (connectedOnce){
    rooms.forEach(room=>{
      socket.join(room)
    })
  }
  connectedOnce = true
});

socket.on("disconnect", (reason) => {
  console.log('disconnect',reason,socket.id, socket.rooms,socket.rooms); // undefined
  rooms.forEach(room=>{
    socket.leave(room)
  })
});
socket.on("disconnecting", (reason) => {
  console.log('disconnecting',reason,socket.id,socket.rooms); // undefined
});

socket.io.on("reconnection_attempt", () => {
  console.log('reconnection_attempt',socket.id); // undefined
});

socket.io.on("reconnect", () => {
  console.log('reconnect',socket.id); // undefined
 
});

socket.on("connect_error", (err) => {
  console.log('connect_error',err); // undefined

});

socket.on("connect_error", (err) => {
  console.log('connect_error',err); // undefined

});
socket.on("join-room", (room, id) => {
  console.log('join-room',room, id); // undefined

});


window.socket = socket
export default socket
