<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="systemActivity"
      :items-per-page="50"
      dense
      class="elevation-1"
      v-if="systemActivity"
      :search="search"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>
              {{ item.startDate | time }} ({{
                item.startDate | ago
              }})
            </td>
            <td>{{ item.vmName }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.pool.name }}</td>

          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Time", value: "startDate" },
        { text: "vmName", value: "vmName" },
        { text: "type", value: "type" },
        { text: "status", value: "status" },
        { text: "pool", value: "pool.name" },

        // { text: "eventErrorDetails", value: "eventErrorDetails" },
        // { text: "extraInfo", value: "extraInfo" },
      ],
    }
  },
  props: ["systemActivity"],
};
</script>

<style>
</style>