<template>
  <div v-if="test" style="">
      <div  class="d-flex">
      <StatNumber v-for="stat in statCards" :key="stat.label" :stat="stat" class="mr-2 mb-2" />
      </div>

      <!-- <div>{{test.desktopAllocation.totals.available/test.desktopAllocation.count*100}}</div>
      <div>{{test.userEventsToday.totals.LOG_ON/test.userEventsToday.totals.ALLOCATE*100}}</div>
      <div>{{test.userEventsLastHour.totals.LOG_ON/test.userEventsLastHour.totals.ALLOCATE*100}}</div>
      <div>{{test.performance.totals.rtt}}</div>
      <div>{{test.performance.totals.fps}}</div>
      <div>{{test.performance.totals.diskIops}}</div>
      <div>{{test.performance.totals.diskLatencyInMs}}</div>
      <div>{{test.performance.totals.sessionLatencyInMs}}</div>
      <div>{{test.performance.totals.sessionCpuPercentage}}</div>
      <div>{{test.performance.totals.sessionMemoryPercentage}}</div>
      
      <pre>
           {{test}}
      </pre> -->
           <!-- <div  class="d-flex" style="height:125px;">

      <div style="width:300px; height: 100px;">
      <Sample v-if="test" :data="test.desktopAllocation.totals" />
      </div>
            <div style="width:300px; height: 100px;">
      <Sample v-if="test" :data="test.desktopAllocation.totals" />
      </div>
            <div style="width:300px; height: 100px;">
      <Sample v-if="test" :data="test.desktopAllocation.totals" />
      </div>
            </div> -->

      <!-- Sample({})
      <pre>
          {{test}}
      </pre> -->
  </div>
</template>

<script>
import vdiAlerts from '../../../utils/vdiAlerts'
// import Sample from './Sample'
import StatNumber from './StatNumber'
export default {
    data(){
        return {
            test: null,
        }
    },
    components:{
        // Sample,
        StatNumber
    },
    props: ['stats'],
    watch:{
        stats:{handler(val){
            console.log('NEW STATS',val)
            if (val && val.desktops){
            this.test = vdiAlerts(val)
            console.log(this.test,'ALERTS')
            }
        },
            immediate: true
        }
    },
    computed:{
        statCards(){
            let arr = []
            let stats = vdiAlerts(this.stats)
            if (!stats || !stats.desktopAllocation) return arr
            console.log('statsstatsstatsstats',{stats})
            arr.push({
                icon: 'mdi-desktop-classic',
                label: 'Desktops Avail',
                value: stats.desktopAllocation.totals.available/stats.desktopAllocation.count*100,
                suffix: '%'
            })
            arr.push({
                icon: 'mdi-hand-coin',
                label: 'Allocation Ratio',
                value:  (stats.userEventsToday.totals.LOG_ON)/(stats.userEventsToday.totals.ALLOCATE-stats.userEventsToday.totals.RECONNECT)*100,
                suffix: '%'
            })
            arr.push({
                icon: 'mdi-play-network',
                label: 'RTT',
                value:  stats.performance.totals.rtt,
                suffix: 'ms'
            })
            arr.push({
                icon: 'mdi-television-play',
                label: 'FPS',
                value:  stats.performance.totals.fps 
            })
            arr.push({
                icon: 'mdi-content-save',
                label: 'Disk IO',
                value:  stats.performance.totals.diskIops
            })
            arr.push({
                icon: 'mdi-harddisk',
                label: 'Disk Latency',
                value:  stats.performance.totals.diskLatencyInMs,
                suffix: 'ms'
            })
            arr.push({
                icon: 'mdi-lan-pending',
                label: 'Session Latency',
                value:  stats.performance.totals.sessionLatencyInMs,
                suffix: 'ms'
            })
            arr.push({
                icon: 'mdi-cpu-64-bit',
                label: 'Session CPU',
                value:  stats.performance.totals.sessionCpuPercentage,
                suffix: '%'
            })
            arr.push({
                icon: 'mdi-chip',
                label: 'Session Mem',
                value:  stats.performance.totals.sessionMemoryPercentage,
                suffix: '%'
            })
            return arr
        }
    }

}
</script>

<style>

</style>