var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pci"},[_c('v-dialog',{attrs:{"width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.articlePreview.title)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.articlePreview.title)+" ")]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.articlePreview.body)}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false;
            _vm.articlePreview = {};}}},[_vm._v(" Close ")])],1)],1):_vm._e()],1),(!_vm.loading)?_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"yellow"}}),_c('v-tab',[_vm._v(" Articles ")]),_c('v-tab',[_vm._v(" "+_vm._s(_vm.existingArticle ? "Edit" : "Create")+" ")]),(_vm.articles.length)?_c('v-tab',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.print.apply(null, arguments)}}},[_vm._v(" Print ")]):_vm._e(),(_vm.attachments.length)?_c('v-tab',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.downloadAttachments.apply(null, arguments)}}},[_vm._v(" Download Attachments ")]):_vm._e()],1):_vm._e(),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"fixed-header":"","items":_vm.items,"item-key":"pci","dense":"","search":_vm.search,"items-per-page":300,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([(!_vm.loading)?{key:"body",fn:function(ref){
            var items = ref.items;
return [_c('tbody',[(_vm.loading)?_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_c('div',{staticClass:"text-center"},[_vm._v("Loading Articles...")]),_c('v-progress-linear',{attrs:{"indeterminate":"","striped":""}})],1)]):_vm._e(),_vm._l((items),function(item){return _c('tr',{key:item.pci},[_c('td',[_vm._v(_vm._s(item.pci.replace("pci.", "").split("-")[0]))]),_c('td',_vm._l((item.articles),function(article){return _c('div',{key:article.id},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","x-small":""},on:{"click":function($event){return _vm.editArticle(article)}}},[_vm._v("Edit")]),_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.articlePreview = article;
                          _vm.dialog = true;}}},[_vm._v(_vm._s(article.title))]),_c('span',[_vm._v(" ("+_vm._s(article.updated)+")")]),_c('v-btn',{attrs:{"small":"","href":article.html_url,"target":"_blank","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1),(article.todo)?_c('v-icon',{attrs:{"color":"orange","small":""}},[_vm._v("mdi-list-status")]):_vm._e(),(
                          article.label_names.indexOf('pci.evidence') > -1
                        )?_c('v-icon',{attrs:{"color":"black","small":""}},[_vm._v("mdi-briefcase")]):_vm._e()],1)}),0),_c('td',[(!item.articles.length)?_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-help-circle")]):(item.todo)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-list-status")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-playlist-check")])],1),_c('td',[(!item.articles.length)?_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-help-circle")]):(item.vendorAOC)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-certificate")]):_vm._e()],1),_c('td',[(!item.articles.length)?_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-help-circle")]):(item.na)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-minus-box-outline")]):_vm._e()],1)])})],2)]}}:null],null,true)})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-combobox',{attrs:{"items":_vm.pciTags,"chips":"","clearable":"","label":"PCI Tags","multiple":"","prepend-icon":"mdi-filter-variant"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var attrs = ref.attrs;
                        var item = ref.item;
                        var select = ref.select;
                        var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"small":"","input-value":selected,"close":"","text-color":"white","color":"primary"},on:{"click":select,"click:close":function($event){return _vm.removeTag(item)}}},'v-chip',attrs,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")],1)]}}]),model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}}),_c('WYSIWYGVue',{staticStyle:{"height":"500px"},model:{value:(_vm.editorContent),callback:function ($$v) {_vm.editorContent=$$v},expression:"editorContent"}})],1),_c('v-card-actions',[((_vm.title || _vm.editorContent) && !_vm.existingArticle)?_c('v-btn',{on:{"click":_vm.clearArticle}},[_vm._v("Clear")]):_vm._e(),_c('v-spacer'),(_vm.title && _vm.editorContent && _vm.existingArticle)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading ||
              !_vm.title.length ||
              !_vm.editorContent.length ||
              !_vm.tags.length},on:{"click":_vm.updateArticle}},[_vm._v("Update Article")]):_c('v-btn',{attrs:{"disabled":_vm.loading ||
              !_vm.title.length ||
              !_vm.editorContent.length ||
              !_vm.tags.length,"color":"success","loading":_vm.loading},on:{"click":_vm.createArticle}},[_vm._v("Create Article")])],1)],1)],1)],1),_c('div',{staticClass:"d-none"},[_c('div',{ref:"print"},_vm._l((_vm.printArticles.filter(
          function (a) { return a.label_names.indexOf('pci.todo') < 0 &&
            a.label_names.indexOf('pci.evidence') < 0; }
        )),function(article){return _c('div',{key:article.id,staticClass:"printPage"},[_c('div',{staticClass:"text-right mt-1"},_vm._l((article.label_names),function(label){return _c('v-chip',{key:label,staticClass:"ml-2 mb-1",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(label)+" ")])}),1),_c('h1',{},[_vm._v(_vm._s(article.title))]),_c('div',{staticClass:"printArticleBody mt-3",domProps:{"innerHTML":_vm._s(article.body)}}),(article.attachments.length)?[_c('h3',{staticClass:"mt-2"},[_vm._v("Files / Evidence")]),_c('ul',_vm._l((article.attachments),function(attachment){return _c('li',{key:attachment.id},[_vm._v(" "+_vm._s(attachment.display_file_name)+" ")])}),0)]:_vm._e(),_c('div',{staticClass:"text-right"},[_c('v-chip',{staticClass:"mr-2",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" #"+_vm._s(article.id)+" ")])],1),_c('div',{staticClass:"pagebreak"})],2)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }