<template>
  <div class="text-center">
    <v-dialog v-model="dialog">
      <v-card v-if="desktop">
        <v-card-title class="text-h5 grey lighten-2">
          Desktop Details for {{ desktop.name }} {{ tab }}
        </v-card-title>

        <v-card-text>
    

          <v-tabs v-model="tab">
            <v-tab>User Logs (24hrs)</v-tab>
            <v-tab>Desktop Logs (24hrs)</v-tab>
            <v-tab>Session Logs (24hrs)</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">time</th>
                          <th class="text-left">userLoginName</th>
                          <th class="text-left">userEvent</th>
                          <th class="text-left">datacenter</th>
                          <th class="text-left">virtualMachineName</th>
                          <th class="text-left">eventError</th>
                          <th class="text-left">eventErrorDetails</th>
                          <th class="text-left">extraInfo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in desktop.userActivityLogs"
                          :key="item.id"
                        >
                          <td>
                            {{ item.reportTimestamp | time }} ({{
                              item.reportTimestamp | ago
                            }})
                          </td>
                          <td>{{ item.userLoginName }}</td>
                          <td>{{ item.userEvent }}</td>
                          <td>{{ item.datacenterName }}</td>
                          <td>{{ item.virtualMachineName }}</td>
                          <td>{{ item.eventError }}</td>
                          <td>{{ item.eventErrorDetails }}</td>
                          <td>{{ item.extraInfo }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">time</th>
                          <th class="text-left">userLoginName</th>
                          <th class="text-left">userEvent</th>
                          <th class="text-left">eventError</th>
                          <th class="text-left">eventErrorDetails</th>
                          <th class="text-left">extraInfo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in desktop.desktopActivityLogs"
                          :key="item.id"
                        >
                          <td>
                            {{ item.reportTimestamp | time }} ({{
                              item.reportTimestamp | ago
                            }})
                          </td>
                          <td>{{ item.userLoginName }}</td>
                          <td>{{ item.userEvent }}</td>
                          <td>{{ item.eventError }}</td>
                          <td>{{ item.eventErrorDetails }}</td>
                          <td>{{ item.extraInfo }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text> TAB {{ tab }} </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> I accept </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      dialog: false,
    };
  },
  watch: {
    desktop(val) {
      this.dialog = val ? true : false;
    },
  },
  props: ["desktop"],
  methods: {
    close() {
      this.dialog = false;
      this.$emit("close");
    },
  },
  mounted() {
    console.log(this.desktop);
  },
};
</script>

<style>
</style>