<template>
  <v-card style="width: 140px" :title="avgValue">
    <v-card-text class="text-center pa-0 pt-2">
      <v-icon x-large>{{ stat.icon }}</v-icon>
      <h1 class="pt-2">{{ value }}{{ stat.suffix }}</h1>
      total {{ avgValue}} - {{ avgValueLast30Min }}
        </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <div class="text-center text-caption">
        <v-icon x-small v-if="value - avgValue !== 0">{{value - avgValue > 0 ? 'mdi-arrow-up-bold' :  'mdi-arrow-down-bold'}}</v-icon>
        <span> {{ stat.label }} ({{ values.length }}) </span>      
        <v-icon x-small v-if="value - avgValueLast30Min !== 0">{{value - avgValueLast30Min > 0 ? 'mdi-arrow-up-bold' :  'mdi-arrow-down-bold'}}</v-icon>

      </div>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      values: [],
      value: 0,
    };
  },
  props: ["stat"],
  computed: {
    avgValue() {
      let sum = this.values.reduce((a, b) => a + b, 0);
      let val = this.values.length ? sum / this.values.length : 0;
      return val.toFixed(1);
    },
    avgValueLast30Min() {
      let sum = this.values.slice(-30).reduce((a, b) => a + b, 0);
      let val = this.values.length ? sum / this.values.length : 0;
      return val.toFixed(1);
    },
  },
  watch: {
    stat: {
      deep: true,
      immediate: true,
      handler(val) {
        this.value = +val.value.toFixed(1);
        this.values.push(val.value);
        if (this.values.length > 60*24){
            this.values.shift()
        }
      },
    },
  },
};
</script>

<style>
</style>