<template>
  <div class="pci">
    <v-dialog v-model="dialog" width="80%">
      <v-card v-if="articlePreview.title">
        <v-card-title class="text-h5 grey lighten-2">
          {{ articlePreview.title }}
        </v-card-title>

        <v-card-text v-html="articlePreview.body"> </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              dialog = false;
              articlePreview = {};
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tabs v-model="tab" align-with-title v-if="!loading">
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab> Articles </v-tab>
      <v-tab> {{ existingArticle ? "Edit" : "Create" }} </v-tab>
      <v-tab v-if="articles.length" @click.stop.prevent="print"> Print </v-tab>
      <v-tab  v-if="attachments.length" @click.stop.prevent="downloadAttachments">
        Download Attachments
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              :headers="headers"
              fixed-header
              :items="items"
              item-key="pci"
              dense
              :search="search"
              class="elevation-1"
              :items-per-page="300"
              :loading="loading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:body="{ items }" v-if="!loading">
                <tbody>
                  <tr v-if="loading">
                    <td colspan="5">
                      <div class="text-center">Loading Articles...</div>
                      <v-progress-linear
                        indeterminate
                        striped
                      ></v-progress-linear>
                    </td>
                  </tr>
                  <tr v-for="item in items" :key="item.pci">
                    <td>{{ item.pci.replace("pci.", "").split("-")[0] }}</td>
                    <td>
                      <div v-for="article in item.articles" :key="article.id">
                        <v-btn
                          class="mr-2"
                          color="secondary"
                          @click="editArticle(article)"
                          x-small
                          >Edit</v-btn
                        >
                        <span
                          class="cursor-pointer"
                          @click="
                            articlePreview = article;
                            dialog = true;
                          "
                          >{{ article.title }}</span
                        >
                        <span> ({{ article.updated }})</span>
                        <v-btn
                          small
                          :href="article.html_url"
                          target="_blank"
                          icon
                        >
                          <v-icon small>mdi-open-in-new</v-icon></v-btn
                        >

                        <v-icon v-if="article.todo" color="orange" small
                          >mdi-list-status</v-icon
                        >
                        <v-icon
                          v-if="
                            article.label_names.indexOf('pci.evidence') > -1
                          "
                          color="black"
                          small
                          >mdi-briefcase</v-icon
                        >
                      </div>
                    </td>
                    <td>
                      <v-icon v-if="!item.articles.length" color="error" small
                        >mdi-help-circle</v-icon
                      >
                      <v-icon v-else-if="item.todo" color="orange"
                        >mdi-list-status</v-icon
                      >
                      <v-icon v-else color="green">mdi-playlist-check</v-icon>
                    </td>
                    <td>
                      <v-icon v-if="!item.articles.length" color="error" small
                        >mdi-help-circle</v-icon
                      >
                      <v-icon v-else-if="item.vendorAOC" color="blue"
                        >mdi-certificate</v-icon
                      >
                    </td>
                    <td>
                      <v-icon v-if="!item.articles.length" color="error" small
                        >mdi-help-circle</v-icon
                      >
                      <v-icon v-else-if="item.na" color="green"
                        >mdi-minus-box-outline</v-icon
                      >
                    </td>
                    <!-- <td>{{item.searchText}}</td> -->
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-text-field label="Title" v-model="title"></v-text-field>

            <v-combobox
              v-model="tags"
              :items="pciTags"
              chips
              clearable
              label="PCI Tags"
              multiple
              prepend-icon="mdi-filter-variant"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  text-color="white"
                  color="primary"
                  @click="select"
                  @click:close="removeTag(item)"
                >
                  <v-icon left> mdi-label </v-icon>
                  <strong>{{ item }}</strong
                  >&nbsp;
                </v-chip>
              </template>
            </v-combobox>

            <WYSIWYGVue v-model="editorContent" style="height: 500px" />
            <!-- <VueTrix v-model="editorContent" placeholder="Enter content" localStorage  class="trix-content"/> -->
            <!-- <div
              ref="result"
              v-html="editorContent"
              style="height: 300px; overflow-y: auto"
            ></div> -->
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="clearArticle"
              v-if="(title || editorContent) && !existingArticle"
              >Clear</v-btn
            ><v-spacer></v-spacer>
            <v-btn
              @click="updateArticle"
              color="primary"
              :loading="loading"
              :disabled="
                loading ||
                !title.length ||
                !editorContent.length ||
                !tags.length
              "
              v-if="title && editorContent && existingArticle"
              >Update Article</v-btn
            >
            <v-btn
              :disabled="
                loading ||
                !title.length ||
                !editorContent.length ||
                !tags.length
              "
              @click="createArticle"
              color="success"
              v-else
              :loading="loading"
              >Create Article</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <div class="d-none">
      <div ref="print">
        <div
          v-for="article in printArticles.filter(
            (a) =>
              a.label_names.indexOf('pci.todo') < 0 &&
              a.label_names.indexOf('pci.evidence') < 0
          )"
          :key="article.id"
          class="printPage"
        >
          <div class="text-right mt-1">
            <v-chip
              v-for="label in article.label_names"
              :key="label"
              label
              small
              color="primary"
              class="ml-2 mb-1"
            >
              {{ label }}
            </v-chip>
          </div>
          <h1 class="">{{ article.title }}</h1>
          <div class="printArticleBody mt-3" v-html="article.body"></div>
          <template v-if="article.attachments.length">
            <h3 class="mt-2">Files / Evidence</h3>
            <ul>
              <li
                v-for="attachment in article.attachments"
                :key="attachment.id"
              >
                {{ attachment.display_file_name }}
              </li>
            </ul>
          </template>
          <div class="text-right">
            <v-chip label small color="primary" class="mr-2">
              #{{ article.id }}
            </v-chip>
          </div>
          <div class="pagebreak"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueTrix from "vue-trix";
import JsZip from "jszip";
import FileSaver from "file-saver";
import firebase from "../../firebase";
const functions = firebase.functions();
let pciFiles = functions.httpsCallable("pciFiles");

import WYSIWYGVue from "../../components/fields/WYSIWYG.vue";
import pcis from "./pcis";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
let editorTimeout = null;
export default {
  data() {
    return {
      loading: false,
      articlePreview: {},
      dialog: false,
      tab: 0,
      editorContent: "",
      title: "",
      search: "",
      tags: [],
      articles: [],
      pciLines: [],
      existingArticle: null,
      attachments: [],
      headers: [
        { text: "PCI", value: "pci" },
        { text: "Articles", value: "articles" },
        { text: "ToDo", value: "todo" },
        { text: "Vendor AOC", value: "vendorAOC" },
        { text: "N/A", value: "na" },
        { text: "Text", value: "searchText" },
      ],
    };
  },

  computed: {
    pciTags() {
      return ["pci.vendor.aoc", "pci.todo", "pci.na", "pci.evidence", ...pcis];
    },
    printArticles() {
      let items = this.items;
      let foundArticles = {};
      let articles = [];
      items.forEach((item) => {
        item.articles.forEach((article) => {
          if (!foundArticles[article.id]) {
            articles.push(article);
            foundArticles[article.id] = article;
          }
        });
      });
      return articles;
    },
    ...mapGetters(["pageVariables"]),
    items() {
      return Object.keys(this.pciLines).map((key) => {
        return {
          pci: key,
          articles: this.pciLines[key].articles,
          todo: this.pciLines[key].articles.filter((a) => a.todo).length > 0,
          vendorAOC: this.pciLines[key].vendorAOC,
          na: this.pciLines[key].na,
          searchText: this.pciLines[key].articles.map((article) =>
            `${article.title} ${article.body} `.toLowerCase()
          ),
        };
      });
    },
  },
  components: {
    // VueTrix,
    WYSIWYGVue,
  },
  mounted() {
    this.getArticles();
  },
  methods: {
    print(evt) {
      evt.preventDefault();

      this.$refs.print.querySelectorAll("a").forEach(a => a.outerHTML = a.innerHTML);

      let oldTitle = document.title;
      document.title = "VIPdesk PCI Policy and Procedures";
      let inner = document.getElementsByClassName("v-application--wrap")[0];
      // inner.style.visibility = 'hidden'
      inner.style.display = "none";
      let container = document.getElementById("hub");
      container.style.display = "block";
      // let currentHTML = container.innerHTML;
      let printHTML = this.$refs.print.innerHTML;
      let wrapper = document.createElement("div");
      wrapper.innerHTML = printHTML;
      container.appendChild(wrapper);
      setTimeout(() => {
        try {
          window.print();
        } catch (e) {
          console.log(e);
        }
        container.removeChild(wrapper);
        inner.style.display = "flex";
        container.style.display = "flex";
        this.tab = 0;
        document.title = oldTitle;

      }, 1500);

      // const mywindow = window.open("", "PRINT", "height=400,width=600");

      // mywindow.document.write("<html><head><title></title>");

      // document.getElementsByTagName("link").forEach((link) => {
      //   mywindow.document.write(`<link href="${link.href}"></link>`);
      // });

      // document.getElementsByTagName("style").forEach((style) => {
      //   mywindow.document.write(`<style>${style.innerHTML}</style>`);
      // });

      // // mywindow.document
      // //   .write(`<style>.pagebreak {clear: both;page-break-after: always;}
      // //   .printHeader{
      // //     position: fixed;
      // //     top: 0;
      // //   }
      // //   .printFooter{
      // //     position: fixed;
      // //     bottom: 0;
      // //   }
      // //   </style>`);

      //   mywindow.document.write("</head><body >");
      //     mywindow.document.write(`
      //   <div  class="v-application v-application--is-ltr theme--light" ><div class="">`);
      // mywindow.document.write(this.$refs.print.innerHTML);
      // mywindow.document.write("</div></div>");
      // mywindow.document.write("</body></html>");

      // mywindow.document.close(); // necessary for IE >= 10
      // mywindow.focus(); // necessary for IE >= 10*/

      // // mywindow.print();
      // // mywindow.close();

      // return true;
    },
    editArticle(article) {
      this.existingArticle = article;
      this.editorContent = article.body;
      this.title = article.title;
      this.tags = article.label_names;
      this.tab = 1;
      console.log(article);
    },
    removeTag(item) {
      this.tags.splice(this.tags.indexOf(item), 1);
    },
    async exportZip(blobs) {
      const zip = JsZip();
      blobs.forEach((blob, i) => {
        zip.file(`file-${i}.csv`, blob);
      });
      zip.generateAsync({ type: "blob" }).then((zipFile) => {
        const currentDate = new Date().getTime();
        const fileName = `combined-${currentDate}.zip`;
        return FileSaver.saveAs(zipFile, fileName);
      });
    },
    async downloadAttachment(url) {
      var link = document.createElement("a");
      link.href = url;
      link.download = url.substr(url.lastIndexOf("/") + 1);
      link.click();

      return fetch(url, {
        // mode:'cors',
        // credentials: "include",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.pageVariables.pci.token,
        },
      }).then((resp) => resp.blob());
    },
    async downloadAttachments() {
      this.tab = 0
      let resp = await pciFiles({
        articles: this.articles,
        token: this.pageVariables.pci.token,
      });
      console.log('downloadAttachments',resp)
      this.tab = 0

      // let files = await Promise.all(this.attachments.map(a => this.downloadAttachment(a.content_url)))
      // // console.log(files)
      // this.exportZip(files)
    },
    async getAttachments(article) {
      // let url = `https://vipdeskconnect1.zendesk.com/api/v2/help_center/en-us/articles/${article.id}/attachments.json`;
      // console.log(url)
      // let resp = await fetch(url, {
      //   headers: {
      //     "content-type": "application/json",
      //     Authorization: "Bearer " + this.pageVariables.pci.token,
      //   },
      // });
      // let data = await resp.json();
      // this.$set(article,'attachments', data.article_attachments || [])
      // this.attachments = this.attachments.concat(article.attachments);
      article.attachments = []
      return article;
    },
    async getArticles(
      url = "https://vipdeskconnect1.zendesk.com/api/v2/help_center/en-us/categories/8474228934292/articles",
      articles = []
    ) {
      this.attachments = [];
      this.articles = [];
      this.loading = true;
      console.log(this.pageVariables.pci.token)
      let resp = await fetch(url, {
        // mode:'cors',
        // credentials: "include",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.pageVariables.pci.token,
        },
      });
      let data = await resp.json();
      articles = articles.concat(data.articles);
      console.log(articles)
      this.articles = articles.filter((article) => article.draft === false);
      this.articles = await Promise.all(this.articles.map(this.getAttachments));
      if (data.next_page) {
        console.log(data.next_page);
        return await this.getArticles(data.next_page, this.articles);
      }
      console.log("DONE ARTICLES", { articles });

      let pci = {};
      [...this.pciTags].forEach((item) => {
        pci[item] = { articles: [], todo: true, na: false, vendorAOC: false };
      });
      this.articles.forEach((article) => {
        article.label_names.sort((a, b) =>
          a.localeCompare(b, undefined, { numeric: true })
        );
        article.created = dayjs(article.created_at).format("YYYY-MM-DD h:mma");
        article.updated = dayjs(article.updated_at).format("YYYY-MM-DD h:mma");
        let vendorAOC = article.label_names.indexOf("pci.vendor.aoc") > -1;
        let todo = article.label_names.indexOf("pci.todo") > -1;
        let na = article.label_names.indexOf("pci.na") > -1;
        article.todo = todo;
        if (article.label_names.length < 2) {
          console.log("**********************************************");
          console.log("**********************************************");
          console.log("**********************************************");
          console.log("**********************************************");
          console.log(article.label_names.length, article);
          console.log("**********************************************");
          console.log("**********************************************");
          console.log("**********************************************");
          console.log("**********************************************");
        }
        // article.label_names = article.label_names.filter(
        //   (label) => label != "pci"
        // );
        if (article.label_names.length == 1) {
          pci["*missing-" + Math.random()] = {
            articles: [article],
            todo: true,
            na: false,
            vendorAOC: false,
          };
        }

        article.label_names.forEach((label) => {
          if (
            label === "pci"
            //||
            // label === "pci.vendor.aoc" ||
            // label === "pci.na" ||
            // label === "pci.todo"
          )
            return;
          if (!pci[label]) {
            pci[label] = { vendorAOC, todo, articles: [], na, missed: true };
          } else {
            pci[label].articles.push(article);
            pci[label].vendorAOC = pci[label].vendorAOC || vendorAOC;
            pci[label].todo = pci[label].todo || todo;
            pci[label].na = pci[label].na || na;
          }
        });
      });
      this.pciLines = Object.keys(pci)
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        .reduce((obj, label) => {
          obj[label] = pci[label];
          return obj;
        }, {});
      // let lines = this.articles.map(article => article.label_names).flat()
      // console.log(lines)
      // lines = lines.sort((a, b) => a.localeCompare(b, undefined, { numeric:true }))
      // this.pciLines = Array.from(new Set(lines))
      this.loading = false;

      return articles;
    },
    clearArticle() {
      this.existingArticle = null;
      this.title = "";
      this.editorContent = "";
      this.tags = [];
    },
    async updateArticle() {
      this.loading = true;
      let payload = {
        label_names: [...new Set(["pci", ...this.tags])],
        title: this.title,
        body: this.editorContent,
      };
      await fetch(
        `https://vipdeskconnect1.zendesk.com/api/v2/help_center/en-us/articles/${this.existingArticle.id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + this.pageVariables.pci.token,
          },
          method: "PUT",
          body: JSON.stringify({ article: payload }),
        }
      );
      await fetch(
        `https://vipdeskconnect1.zendesk.com/api/v2/help_center/articles/${this.existingArticle.id}/translations/en-us.json`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + this.pageVariables.pci.token,
          },
          method: "PUT",
          body: JSON.stringify({ translation: payload }),
        }
      );
      this.clearArticle();
      this.tab = 0;
    },
    async createArticle() {
      this.loading = true;

      this.editorContent = this.editorContent.replace(/(<p><\/p>)/gim, "");
      await fetch(
        "https://vipdeskconnect1.zendesk.com/api/v2/help_center/en-us/sections/8474268323860/articles",
        {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + this.pageVariables.pci.token,
          },
          method: "POST",
          body: JSON.stringify({
            article: {
              locale: "en-us",
              user_segment_id: 76743,
              permission_group_id: 36806,
              section_id: 8474268323860,
              label_names: [...new Set(["pci", ...this.tags])],
              title: this.title,
              body: this.editorContent,
            },
          }),
        }
      );
      this.clearArticle();
      this.tab = 0;
    },
    copyToClipboard() {
      const type = "text/html";
      const blob = new Blob([this.$refs.result.innerHTML], { type });
      const data = [new window.ClipboardItem({ [type]: blob })];

      navigator.clipboard.write(data).then(
        () => {
          this.$refs.result.style.opacity = "0.4";

          setTimeout(function () {
            this.$refs.result.style.opacity = "1";
          }, 80);
        },
        function () {
          // Fallback in case the copy did not work
        }
      );
    },
  },
  watch: {
    tab(val) {
      if (val == 0) {
        this.getArticles();
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0;

    },
    editorContent(val, oldVal) {
      clearTimeout(editorTimeout);
      console.log({ val });
      if (val === oldVal) return console.log("NO CHANGE");
      editorTimeout = setTimeout(() => {
        this.editorContent = val
          .replace(/(VIPdeskConnect)/gim, "VIPdesk")
          .replace(/(VIPdesk Connect)/gim, "VIPdesk")
          .replace(/(VP of Technology)/gim, "CTO")
          .replace(/(Chief Technology Officer)/gim, "CTO")
          .replace(/(TVAdmins)/gim, "VDI Admins")
          .replace(/(&nbsp;)/gim, " ")
          .replace(/(<p> <\/p>)/gim, "");

        if (!this.title && this.editorContent)
          this.title = this.$refs.result.querySelector("p").innerText;
      }, 100);
    },
  },
};
</script>

<style>
.printArticleBody {
  font-size: 18px !important;
  line-height: 1.5 !important;
  overflow: visible;
  max-width: 100vw;
  word-wrap: normal;
  white-space: wrap;
}
.pagebreak {
  clear: both;
  page-break-after: always !important;
}

trix-toolbar .trix-button-row span.trix-button-group {
  padding: 10px;
  background-color: #fff !important;
}
trix-editor {
  height: 300px;
  overflow: auto;
  background-color: #fff !important;
  padding: 10px !important;
}
td {
  padding: 2px 10px;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}
.pci th:last-child {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
* {
    font-family: "Open Sans" !important;
  }
@media print {
  * {
    font-family: "Open Sans" !important;
  }
  ol,
  ul {
    margin-left: 0.5in !important;
    margin-bottom: .25in !important;
  }
  table{
    border-collapse: collapse!important;
    border: 1px solid #AAA!important;
    width: 100%!important;
    margin-bottom: 20px!important;
  }
  tbody tr:nth-child(odd) {
   background-color: #EEE!important;
}
  img {
    margin: auto !important;
    max-width: 80% !important;
    max-height: 80%!important;
  }
  .printArticleBody {
    font-size: 18px !important;
    line-height: 1.5 !important;
    overflow: visible;
    max-width: 100%;
    word-wrap: normal;
    white-space: wrap;
  }
  @page {
    size: 8.5in 11in;
    margin: 0.5in;
  }
}
</style>